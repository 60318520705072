import axios, { AxiosError, AxiosResponse } from "axios";
import {
  SimulatorSessionInfo,
  SimulatorTestDeviceConfiguration,
  ParsedSimulatorTestDeviceConfiguration,
} from "./types";
import { atomWithMutation } from "jotai-tanstack-query";
import { delay, poll, retryOperation } from "./helpers";
import Fuse from "fuse.js";
import { FASTAPI_SERVER_URL } from "../constants/aws-constants";

const kServerUrl = "https://device-hub.mobileboost.io";

const axiosAuth = axios.create({
  auth: {
    username: "mobileboost",
    password: "mobileboost-pass",
  },
});

interface Device {
  screen: { width: number; height: number };
  platform: string;
}

interface SimulatorSession {
  stop: () => Promise<void>;
  checkIfSessionIsRunning: () => Promise<boolean>;

  getUI(): Promise<string>;

  getScreenshot: () => Promise<string>;
  device: Device;
  startedAt: number;
  info: SimulatorSessionInfo;
  serverUrl: string;
  launchApp: () => Promise<void>;
  commands: {
    tap: ({
      coordinates,
      duration,
    }: {
      coordinates: { x: number; y: number };
      duration: number;
    }) => Promise<void>;
    tapOnElement: ({
      text,
      id,
      duration,
    }: {
      text?: string;
      id?: string;
      duration: number;
    }) => Promise<void>;
    type: ({ text }: { text: string }) => Promise<void>;
    keyStroke: ({ keycode }: { keycode: number }) => Promise<void>;
    openDeepLinkUrl: (url: string) => Promise<void>;
    swipe: ({
      startCoordinates,
      endCoordinates,
      duration,
    }: {
      startCoordinates: { x: number; y: number };
      endCoordinates: { x: number; y: number };
      duration?: number;
    }) => Promise<void>;
    tapHomeScreen: () => Promise<void>;
  };
}

interface CreateSessionMutationAtomParams {
  platform: string;
  device: string;
  os: string;
  buildId: string;
  bundleId: string;
  storagePath: string;
  language?: string;
  countryCode?: string;
  proxy?: string;
  recordVideo?: boolean;
  onCleanup: () => void;
  organisationId: string;
}

const sessionMutationAtom = atomWithMutation((get) => ({
  mutationKey: ["createSessionMutation"],
  mutationFn: createSimulatorSession,
}));

// Create a cleanup function to handle session termination
const cleanupSession = async (
  sessionId: string | null,
  pc: RTCPeerConnection | null,
  serverUrl: string
) => {
  try {
    // Close WebRTC connection
    if (pc) {
      pc.close();
    }

    // Remove video element
    const deviceWrapper = document.getElementById("deviceWrapper");
    const videoElement = deviceWrapper?.querySelector("video");
    if (videoElement) {
      videoElement.srcObject = null;
      deviceWrapper?.removeChild(videoElement);
    }

    // Stop session on server if session ID exists
    if (sessionId) {
      await axios.post(`${serverUrl}/stopSession`, {
        session_id: sessionId,
      });
    }
  } catch (error) {
    console.error("Error during session cleanup:", error);
    throw error;
  }
};

const createSimulatorSession = async ({
  platform,
  device,
  os,
  buildId,
  bundleId,
  storagePath,
  language,
  countryCode,
  proxy,
  recordVideo = true,
  onCleanup,
  organisationId,
}: CreateSessionMutationAtomParams) => {
  let sessionId: string | null = null;
  let reconnectionAttempts = 0;
  const MAX_RECONNECTION_ATTEMPTS = 3;
  const RECONNECTION_DELAY = 5000; // 5 seconds
  let reconnectionTimer: NodeJS.Timeout | null = null;

  let sessionResponse;

  await retryOperation(
    async (_) => {
      try {
        const deviceConfigResponse = await axiosAuth.get(
          `${kServerUrl}/devices`
        );

        const unparsedDeviceConfigs: SimulatorTestDeviceConfiguration[] =
          deviceConfigResponse.data;

        const deviceConfig = getDeviceConfig({
          platform,
          os,
          device,
          availableDeviceConfigs: unparsedDeviceConfigs,
        });

        sessionResponse = await axios.post(
          `${FASTAPI_SERVER_URL}/device_hub/start_session`,
          {
            device_name: deviceConfig.device,
            osVersion: deviceConfig.os,
            build_id: buildId,
            bundle_id: bundleId,
            language,
            locale: countryCode,
            save_recording: recordVideo,
            proxy,
            organisation_id: organisationId,
          }
        );
      } catch (e: any) {
        if (e?.response?.data?.detail === "INVALID_DEVICE_CONFIGURATION") {
          throw { ...e, cancelRetry: true };
        } else if (e?.response?.status === 404) {
          throw {
            ...e,
            message:
              "No devices available for your account right now. Please try again shortly",
            cancelRetry: true,
          };
        } else if (e?.response?.status === 429) {
          throw {
            ...e,
            message:
              "The limit of parallel devices on your account has been reached. Please close other connections and try again.",
            cancelRetry: true,
          };
        }
        throw e;
      }
    },
    10000,
    3
  );

  sessionId = sessionResponse!.data.session_id;
  const instanceIp = sessionResponse!.data.instanceIp;

  const launchApp = async () => {
    await axios.get(`${instanceIp}/interaction/launch_app/${sessionId}`);
  };

  try {
    const sessionInfo = await poll<SimulatorSessionInfo>(
      `${instanceIp!}/session/${sessionId}`,
      (response) => {
        return response.data.state === "assigned";
      },
      5,
      25,
      axios
    );

    if (platform.toLowerCase() === "android") {
      await launchApp();
    }

    let pc = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    });

    const attemptReconnection = async () => {
      if (reconnectionAttempts >= MAX_RECONNECTION_ATTEMPTS) {
        console.log("Max reconnection attempts reached, cleaning up session");
        await cleanupSession(sessionId, pc, instanceIp);
        onCleanup();
        return;
      }

      reconnectionAttempts++;
      console.log(
        `Attempting reconnection (${reconnectionAttempts}/${MAX_RECONNECTION_ATTEMPTS})`
      );

      // Wait for connection to potentially recover
      reconnectionTimer = setTimeout(async () => {
        if (pc.connectionState !== "connected") {
          await attemptReconnection();
        }
      }, RECONNECTION_DELAY);
    };

    // Add connection state change handler with reconnection logic
    pc.onconnectionstatechange = async () => {
      console.log("Connection state changed:", pc.connectionState);

      // Clear any existing reconnection timer
      if (reconnectionTimer) {
        clearTimeout(reconnectionTimer);
        reconnectionTimer = null;
      }

      switch (pc.connectionState) {
        case "disconnected":
          // Start reconnection process for disconnected state
          console.log("Connection disconnected, attempting to reconnect");
          await attemptReconnection();
          break;

        case "failed":
          // For failed state, set counter so next attempt is the last one
          console.log("Connection failed, attempting emergency reconnection");
          reconnectionAttempts =
            MAX_RECONNECTION_ATTEMPTS - (MAX_RECONNECTION_ATTEMPTS - 1); // This will become MAX_RECONNECTION_ATTEMPTS - 1 after increment
          await attemptReconnection();
          break;

        case "closed":
          // Clean up immediately for explicitly closed connections
          console.log("Connection closed, cleaning up");
          await cleanupSession(sessionId, pc, instanceIp);
          onCleanup();
          break;

        case "connected":
          // Reset reconnection attempts when successfully connected
          reconnectionAttempts = 0;
          break;
      }
    };

    pc.ontrack = function (event) {
      console.log("Track event: ", event);

      event.streams.forEach((stream) => {
        stream.getTracks().forEach((track) => {
          console.log("Track kind: ", track.kind);

          if (track.kind === "video") {
            var videoElement = document.createElement("video");
            videoElement.srcObject = stream;
            videoElement.autoplay = true;
            videoElement.controls = false;
            videoElement.playsInline = true;
            videoElement.preload = "none";
            videoElement.playbackRate = 1.0;

            videoElement.addEventListener("loadedmetadata", () => {
              console.log("Video metadata loaded");
              console.log(
                "Video dimensions: ",
                videoElement.videoWidth,
                "x",
                videoElement.videoHeight
              );

              videoElement.style.maxWidth = "100%";
              videoElement.style.maxHeight = "100%";
              videoElement.style.width = "100%";
              videoElement.style.height = "100%";

              const deviceWrapperElement =
                document.getElementById("deviceWrapper");
              // remove video child if exists
              const existingVideoChild =
                deviceWrapperElement?.querySelector("video");
              if (existingVideoChild) {
                deviceWrapperElement?.removeChild(existingVideoChild);
              }
              deviceWrapperElement?.appendChild(videoElement);
            });

            videoElement.addEventListener("error", (e) => {
              console.error("Video element error: ", e);
            });
          }
        });
      });
    };

    pc.addTransceiver("video", { direction: "recvonly" });

    const localDescription = await pc.createOffer();
    await pc.setLocalDescription(localDescription);
    const base64LocalDescription = btoa(JSON.stringify(localDescription));

    const serverDescriptionResponse = await axios.post(`${instanceIp!}/sdp/`, {
      session_id: sessionId,
      sdp: base64LocalDescription,
    });
    const serverDescription = serverDescriptionResponse.data.sdp;
    console.log("serverDescription", serverDescription);
    await pc.setRemoteDescription(
      new RTCSessionDescription(JSON.parse(atob(serverDescription)))
    );

    const deviceDimensionsResponse: AxiosResponse = await retryOperation(
      (_) => axios.get(`${instanceIp}/interaction/get_dimensions/${sessionId}`),
      5000,
      10
    );

    let deviceDimensions: { width: number; height: number } =
      deviceDimensionsResponse.data;

    return {
      serverUrl: instanceIp,
      info: sessionInfo.data,
      startedAt: new Date().getTime(),
      device: {
        screen: deviceDimensions,
        platform,
      },
      checkIfSessionIsRunning: async () => {
        const sessionStatusResponse = await axios.get(
          `${instanceIp}/session/${sessionId}`
        );
        return sessionStatusResponse.data.state === "assigned";
      },
      stop: async () => {
        await cleanupSession(sessionId, pc, instanceIp);
        onCleanup();
      },
      getScreenshot: async () => {
        const screenshotResponse = await axios.get(
          `${instanceIp}/interaction/screen_shot/${sessionId}`
        );
        return screenshotResponse.data;
      },
      getUI: async () => {
        const pageSourceResponse = await axios.get(
          `${instanceIp}/interaction/page_source/${sessionId}`
        );
        return pageSourceResponse.data;
      },
      launchApp,
      commands: {
        openDeepLinkUrl: async (url: string) => {
          await axios.post(`${instanceIp}/interaction/open_link/${sessionId}`, {
            url,
          });
        },
        tap: async ({
          coordinates,
          duration,
        }: {
          coordinates: { x: number; y: number };
          duration: number;
        }) => {
          await axios.post(`${instanceIp}/interaction/tap/${sessionId}`, {
            position: [coordinates.x, coordinates.y],
            duration,
          });
        },
        tapOnElement: async ({
          text,
          id,
          duration,
        }: {
          text?: string;
          id?: string;
          duration: number;
        }) => {
          const response = await axios.post(
            `${instanceIp}/interaction/tap_element/${sessionId}`,
            {
              text,
              id,
              duration,
            }
          );
          if (
            response?.data != null &&
            response.data.message === "ELEMENT_NOT_FOUND"
          ) {
            const identifier = text ?? id ?? "unknown";
            throw new Error(`Element [${identifier}] not found!`);
          }
        },
        doubleTap: async ({
          coordinates,
          duration,
          count = 1,
        }: {
          coordinates: { x: number; y: number };
          duration: number;
          count: number;
        }) => {
          await axios.post(
            `${instanceIp}/interaction/double_tap/${sessionId}`,
            {
              position: [coordinates.x, coordinates.y],
              duration,
              count,
            }
          );
        },
        longPress: async ({
          coordinates,
          duration,
          count = 1,
        }: {
          coordinates: { x: number; y: number };
          duration: number;
          count: number;
        }) => {
          await axios.post(
            `${instanceIp}/interaction/long_press/${sessionId}`,
            {
              position: [coordinates.x, coordinates.y],
              duration,
              count,
            }
          );
        },
        swipe: async ({
          startCoordinates,
          endCoordinates,
          duration,
        }: {
          startCoordinates: { x: number; y: number };
          endCoordinates: { x: number; y: number };
          duration?: number;
        }) => {
          await axios.post(`${instanceIp}/interaction/swipe/${sessionId}`, {
            start: [
              Math.floor(startCoordinates.x),
              Math.floor(startCoordinates.y),
            ],
            end: [Math.floor(endCoordinates.x), Math.floor(endCoordinates.y)],
            ...(duration != null && { duration }),
          });
        },
        type: async ({ text }: { text: string }) => {
          await axios.post(`${instanceIp}/interaction/text/${sessionId}`, {
            text,
          });
        },
        keyStroke: async ({ keycode }: { keycode: number }) => {
          await axios.post(`${instanceIp}/interaction/keystroke/${sessionId}`, {
            keycode,
          });
        },
        tapHomeScreen: async () => {
          await axios.get(`${instanceIp}/interaction/home_button/${sessionId}`);
        },
      },
    } as SimulatorSession;
  } catch (e) {
    console.log("e", e);
    await axios.post(`${instanceIp!}/stopSession`, {
      session_id: sessionId,
    });
    throw e;
  }
};
const getDeviceConfig = ({
  platform,
  os,
  device,
  availableDeviceConfigs,
}: {
  platform: string;
  os: string;
  device: string;
  availableDeviceConfigs: SimulatorTestDeviceConfiguration[];
}): ParsedSimulatorTestDeviceConfiguration => {
  const parsedData = availableDeviceConfigs
    .map((item) => {
      return item.osVersions.map((os) => ({
        id: item.id,
        platform: item.platform,
        os,
        device: item.name,
      }));
    })
    .flat()
    .sort((a, b) => a.id.localeCompare(b.id));
  console.log("parsedData", parsedData);

  const fuseOptions = {
    // isCaseSensitive: false,
    includeScore: true,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.9,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["os", "id"],
  };

  const fuse = new Fuse(parsedData, fuseOptions);
  const searchPattern = `${os} ${device}`;
  console.log("searchPattern", searchPattern);
  const result = fuse.search(searchPattern);
  console.log("result", result);
  return result[0].item;
};

// const eventToScaledCoordinates = (event) => {
//   // console.log(event)
//   const imageScale = 1; // we downsized the original by factor of two (1 = 100%, 2 = 50%, 4 = 25%)
//   // const [ originalWidth, originalHeight ] = [ event.target.naturalWidth || event.target.videoWidth, event.target.naturalHeight || event.target.videoHeight ];
//   const originalHeight = event.target.height;
//   const originalWidth = event.target.width;
//   const rect = event.target.getBoundingClientRect();
//   const scaleX = originalWidth / rect.width; // relationship bitmap vs. element for X
//   const scaleY = originalHeight / rect.height; // relationship bitmap vs. element for Y
//
//   const x = Math.round((event.clientX - rect.left) * scaleX); // scale mouse coordinates after they have
//   const y = Math.round((event.clientY - rect.top) * scaleY); // been adjusted to be relative to element
//
//   return [x, y, originalWidth, originalHeight].map((n) => n * imageScale);
// };

// function addEventListeners(element) {
//   element.addEventListener("mousedown", function (event) {
//     const [clickX, clickY, originalWidth, originalHeight] =
//       eventToScaledCoordinates(event);
//
//     console.log("mousedown!", {
//       clickX,
//       clickY,
//       originalWidth,
//       originalHeight,
//     });
//
//     // sendChannel.send(
//     //   JSON.stringify({
//     //     command: "MOUSEDOWN",
//     //     data: {
//     //       x: clickX,
//     //       y: clickY,
//     //       w: originalWidth,
//     //       h: originalHeight,
//     //     },
//     //   })
//     // );
//
//     // isDuringTap = true;
//
//     event.preventDefault();
//   });
//
//   element.addEventListener("mouseup", function (event) {
//     const [clickX, clickY, originalWidth, originalHeight] =
//       eventToScaledCoordinates(event);
//
//     console.log("mouseup!", {
//       clickX,
//       clickY,
//       originalWidth,
//       originalHeight,
//     });
//
//     // sendChannel.send(
//     //   JSON.stringify({
//     //     command: "MOUSEUP",
//     //     data: {
//     //       x: clickX,
//     //       y: clickY,
//     //       w: originalWidth,
//     //       h: originalHeight,
//     //     },
//     //   })
//     // );
//
//     // isDuringTap = false;
//
//     event.preventDefault();
//   });
//
//   element.addEventListener("mousemove", function (event) {
//     const [clickX, clickY, originalWidth, originalHeight] =
//       eventToScaledCoordinates(event);
//
//     // if (new Date() - lastMoveTime < 200) {
//     //   return; // do not spam mouse position more than 15 fps
//     // }
//     //
//     // if (!isDuringTap) {
//     //   return; // we do not care about events unless we have the finger down
//     // }
//
//     console.log("mousemove!", {
//       // isDuringTap,
//       clickX,
//       clickY,
//       originalWidth,
//       originalHeight,
//       event,
//     });
//
//     // sendChannel.send(
//     //   JSON.stringify({
//     //     command: "MOUSEMOVE",
//     //     data: {
//     //       x: clickX,
//     //       y: clickY,
//     //       w: originalWidth,
//     //       h: originalHeight,
//     //     },
//     //   })
//     // );
//
//     // lastMoveTime = +new Date();
//
//     event.preventDefault();
//   });
// }

export { sessionMutationAtom, type SimulatorSession, type Device };
