import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { DateTime } from "luxon";
import { ArrowUpRightIcon, ClockIcon } from "@heroicons/react/24/outline";
import { collection, onSnapshot, or, query, where } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { TestRun, TestRunRow, TestSuite } from "../../utils/types";
import { customFirestore } from "../../firebase";
import { AuthContext } from "../../auth/AuthContext";
import {
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { BACKEND_SERVER_URL } from "../../constants/aws-constants";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import { Loop, CancelOutlined } from "@mui/icons-material";
import { callCancelSuiteAPI, trackSentryException } from "../../utils/helpers";
import { useSetAtom } from "jotai/index";
import { customAlertStateAtom } from "../../components/custom-alert";

const ReportsPage = () => {
  const { user } = useContext<any>(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<TestSuite[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const firebaseQuery = query(
      collection(customFirestore, `custom-test-suite-runs`),
      or(
        where("organizationId", "==", user.companyId),
        where("organisationId", "==", user.companyId)
      )
    );
    const unsubscribe = onSnapshot(firebaseQuery, (snapshot) => {
      if (snapshot.empty) {
        setData([]);
      }

      const reports = snapshot.docs.map((report) => {
        const reportData = report.data();

        return {
          id: report.id,
          ...reportData,
        };
      }) as TestSuite[];

      reports.sort((a, b) => b.timeInEpoch - a.timeInEpoch);
      setLoading(false);
      setData(reports);
    });
    return () => {
      unsubscribe();
    };
  }, [user.companyId]);

  return (
    <div className="flex-1 flex flex-col bg-[#E6E6E6] h-full">
      <div className="flex flex-row flex-1 h-[calc(100vh_-_60px)]">
        {loading && (
          <div
            className={
              "flex flex-col h-full items-center justify-center w-full"
            }
          >
            <CircularProgress />
          </div>
        )}
        {error && <p>Error: {error}</p>}
        {!loading && !error && data != null && <ReportSuccess reports={data} />}
      </div>
    </div>
  );
};

interface ReportSuccessProps {
  reports: TestSuite[];
}

function ReportSuccess({ reports }: ReportSuccessProps) {
  const virtuosoTableComponents = useMemo<TableComponents<TestSuite>>(() => {
    return {
      Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
      )),
      Table: (props) => (
        <Table
          {...props}
          sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
        />
      ),
      TableHead,
      TableRow: ({ item: _item, ...props }) => (
        <RowContent {...props} reportData={_item} />
      ),
      TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => {
        if (reports.length === 0) {
          return (
            <div
              className={
                "absolute w-full h-[calc(100%_-_57px)] top-1/2 bottom-1/2"
              }
            >
              <p className={"text-center text-[14px]"}>
                No test runs in the cloud have been started yet
              </p>
            </div>
          );
        }
        return <TableBody {...props} ref={ref}></TableBody>;
      }),
    };
  }, [reports]);

  return (
    <div className="flex flex-1 gap-3 w-full bg-[#D9D9D9] p-4">
      <div
        className={
          "flex flex-1 flex-col bg-[#E8E8E8] h-full p-4 rounded-[8px] gap-8 w-4/6"
        }
      >
        <p className={"text-[24px] font-medium"}>Reports</p>
        <TableVirtuoso
          data={reports}
          components={virtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          increaseViewportBy={1000}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    field: "timeInEpoch",
    headerName: "Time",
    filterable: false,
    sortable: false,
    width: 200,
  },
  {
    field: "trigger",
    headerName: "Trigger",
    filterable: false,
    sortable: false,
    width: 88,
  },
  {
    field: "appVersionName",
    headerName: "App Version",
    filterable: false,
    sortable: false,
  },
  {
    field: "tests",
    headerName: "# Tests",
    filterable: false,
    sortable: false,
  },
  {
    field: "succeeded",
    headerName: "# Succeeded",
    filterable: false,
    sortable: false,
  },
  {
    field: "failed",
    headerName: "# Failed",
    filterable: false,
    sortable: false,
  },
  {
    field: "blocked",
    headerName: "# Blocked",
    filterable: false,
    sortable: false,
  },
  {
    field: "cancelled",
    headerName: "# Cancelled",
    filterable: false,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    filterable: false,
    sortable: false,
  },
];

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.field}
          variant="head"
          sx={{
            backgroundColor: "background.paper",
            width: column.width,
          }}
        >
          {column.headerName}
        </TableCell>
      ))}
    </TableRow>
  );
}

function RowContent(props) {
  const { reportData, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState<object>({
    scheduled: [],
    running: [],
    succeeded: [],
    failed: [],
    blocked: [],
    cancelled: [],
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(
        customFirestore,
        `custom-test-suite-runs/${reportData.id}/runs`
      ),
      (snapshot) => {
        const newData = snapshot.docs.reduce(
          (
            prev: {
              scheduled: TestRun[];
              running: TestRun[];
              succeeded: TestRun[];
              failed: TestRun[];
              blocked: TestRun[];
              cancelled: TestRun[];
            },
            current
          ) => {
            const currentData = current.data() as TestRun;
            const parsedData: TestRun = {
              ...currentData,
              id: current.id,
              testSuiteId: reportData.id,
              osVersion: current.data().os,
            };

            return {
              scheduled: [
                ...prev.scheduled,
                ...(parsedData.status === "initial" ? [parsedData] : []),
              ],
              running: [
                ...prev.running,
                ...(parsedData.status === "running" ? [parsedData] : []),
              ],
              succeeded: [
                ...prev.succeeded,
                ...(parsedData.status === "succeeded" ? [parsedData] : []),
              ],
              failed: [
                ...prev.failed,
                ...(parsedData.status === "failed" ? [parsedData] : []),
              ],
              blocked: [
                ...prev.blocked,
                ...(parsedData.status === "blocked" ? [parsedData] : []),
              ],
              cancelled: [
                ...prev.cancelled,
                ...(parsedData.status === "cancelled" ? [parsedData] : []),
              ],
            };
          },
          {
            scheduled: [],
            running: [],
            succeeded: [],
            failed: [],
            blocked: [],
            cancelled: [],
          }
        );

        setData(newData);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [reportData.id]);

  const parsedData: {
    scheduled: TestRun[];
    running: TestRun[];
    succeeded: TestRun[];
    failed: TestRun[];
    blocked: TestRun[];
    cancelled: TestRun[];
  } = data;

  const testAmount =
    parsedData?.scheduled.length +
    parsedData?.running.length +
    parsedData?.failed.length +
    parsedData?.succeeded.length +
    parsedData?.blocked.length +
    parsedData?.cancelled.length;

  const retryTests = async (status: string) => {
    try {
      const url = `${BACKEND_SERVER_URL}/retrySuite`;

      const body = {
        organisationId: reportData.organisationId,
        testSuiteId: reportData.id,
        status,
      };
      console.log("calling: ", url, "with body", body);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status === 200) {
        console.log("Response", response);
      } else {
        console.error("Failed to retry suite", response);
      }
    } catch (error) {
      trackSentryException(error);
      console.error("Error:", error);
    }
  };

  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} {...rest}>
        <TableCell colSpan={9} padding={"none"}>
          <Table sx={{ borderCollapse: "separate", tableLayout: "fixed" }}>
            <TableHead
              sx={{
                visibility: "collapse",
              }}
            >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    variant="head"
                    sx={{
                      backgroundColor: "background.paper",
                      width: column.width,
                    }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                className={"cursor-pointer"}
                onClick={() => setOpen(!open)}
              >
                <TableCell key={"timeInEpoch"} sx={{ borderBottom: 0 }}>
                  {DateTime.fromMillis(reportData.timeInEpoch * 1000).toFormat(
                    "yyyy-MM-dd hh:mm a"
                  )}
                </TableCell>
                <TableCell key={"trigger"} sx={{ borderBottom: 0 }}>
                  {reportData.trigger}
                </TableCell>
                <TableCell key={"appVersionName"} sx={{ borderBottom: 0 }}>
                  {reportData.appVersionName}
                </TableCell>
                <TableCell key={"testAmount"} sx={{ borderBottom: 0 }}>
                  {data != null && testAmount > 0
                    ? `${testAmount} Test${testAmount > 1 ? "s" : ""}`
                    : "---"}
                </TableCell>
                <TableCell key={"succeeded"} sx={{ borderBottom: 0 }}>
                  <p className={"text-[#17BD27]"}>
                    {data != null && parsedData.succeeded.length > 0
                      ? `${parsedData.succeeded.length} Test${
                          parsedData.succeeded.length > 1 ? "s" : ""
                        }`
                      : "---"}
                  </p>
                </TableCell>
                <TableCell key={"failed"} sx={{ borderBottom: 0 }}>
                  <p className={"text-[#FF4C4C]"}>
                    {data != null && parsedData.failed.length > 0
                      ? `${parsedData.failed.length} Test${
                          parsedData.failed.length > 1 ? "s" : ""
                        }`
                      : "---"}
                    {data != null &&
                      parsedData.failed.length > 0 &&
                      ["completed", "cancelled"].includes(reportData.status) && (
                        <Tooltip title={"Retry failed tests"}>
                          <IconButton
                            onClick={async (event) => {
                              event.stopPropagation(); // This will prevent event from bubbling up to the accordion
                              await retryTests("failed");
                            }}
                          >
                            <Loop />
                          </IconButton>
                        </Tooltip>
                      )}
                  </p>
                </TableCell>
                <TableCell key={"blocked"} sx={{ borderBottom: 0 }}>
                  <p className={"text-orange-400"}>
                    {data != null &&
                    parsedData.blocked.length > 0 &&
                    ["completed", "cancelled"].includes(reportData.status)
                      ? `${parsedData.blocked.length} Test${
                          parsedData.blocked.length > 1 ? "s" : ""
                        }`
                      : "---"}
                    {data != null &&
                      parsedData.blocked.length > 0 &&
                      ["completed", "cancelled"].includes(reportData.status) && (
                        <Tooltip title={"Retry blocked tests"}>
                          <IconButton
                            onClick={async (event) => {
                              event.stopPropagation(); // This will prevent event from bubbling up to the accordion
                              await retryTests("blocked");
                            }}
                          >
                            <Loop />
                          </IconButton>
                        </Tooltip>
                      )}
                  </p>
                </TableCell>
                <TableCell key={"cancelled"} sx={{ borderBottom: 0 }}>
                  <p className={"text-gray-600"}>
                    {data != null && parsedData.cancelled.length > 0
                      ? `${parsedData.cancelled.length} Test${
                          parsedData.cancelled.length > 1 ? "s" : ""
                        }`
                      : "---"}
                  </p>
                </TableCell>
                <TableCell
                  key={"status"}
                  sx={{
                    borderBottom: 0,
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  {reportData.status}
                  {data != null && reportData.status === "running" && (
                    <Tooltip title={"Cancel whole test suite"}>
                      <IconButton
                        sx={{ marginBlock: "-7px" }}
                        size={"small"}
                        onClick={async (event) => {
                          event.stopPropagation(); // This will prevent event from bubbling up to the accordion
                          try {
                            await callCancelSuiteAPI(false, reportData.id);
                          } catch (_) {
                            setCustomAlertState({
                              open: true,
                              type: "error",
                              title: "Something went wrong",
                              description:
                                "An error occurred while canceling the suite execution",
                            });
                          }
                        }}
                      >
                        <CancelOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ padding: 0 }}
                  colSpan={9}
                  sx={{ borderBottom: 0 }}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className={"flex flex-col gap-6 p-4"}>
                      {data != null && parsedData.failed.length > 0 && (
                        <ReportDetailNew
                          succeed={false}
                          results={parsedData.failed}
                        />
                      )}
                      {data != null && parsedData.succeeded.length > 0 && (
                        <ReportDetailNew
                          succeed={true}
                          results={parsedData.succeeded}
                        />
                      )}
                      {data != null && parsedData.running.length > 0 && (
                        <ReportDetailNew results={parsedData.running} />
                      )}
                      {data != null && parsedData.scheduled.length > 0 && (
                        <ReportDetailNew results={parsedData.scheduled} />
                      )}
                      {data != null && parsedData.blocked.length > 0 && (
                        <ReportDetailNew results={parsedData.blocked} />
                      )}
                      {data != null && parsedData.cancelled.length > 0 && (
                        <ReportDetailNew results={parsedData.cancelled} />
                      )}
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface ReportDetailProps {
  succeed?: boolean;
  results: TestRun[];
}

const ReportRowBody = ({ result }: TestRun) => {
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  return (
    <>
      <div className="col-span-4">{result.title}</div>
      <div className="col-span-1">{result.device}</div>
      <div className="col-span-1">{result.osVersion}</div>
      <div className="col-span-1">{result.locale}</div>
      <div
        className="col-span-2"
        style={{
          justifySelf: "end",
        }}
      >
        {result.status !== undefined &&
          (result.status === "succeeded" ||
            result.status === "failed" ||
            result.status === "blocked") && (
            <Link to={`/recording/${result.testSuiteId}/runs/${result.id}`}>
              <span className="underline hover:cursor-pointer">
                view recording
              </span>
              <ArrowUpRightIcon className="inline w-3 h3 ml-1 hover:cursor-pointer" />
            </Link>
          )}
        {result.status !== undefined &&
          ["running", "initial"].includes(result.status) && (
            <Tooltip title={"Cancel single test"}>
              <IconButton
                size={"small"}
                sx={{ marginBlock: "-5px" }}
                onClick={async (event) => {
                  event.stopPropagation(); // This will prevent event from bubbling up to the accordion
                  try {
                    await callCancelSuiteAPI(
                      true,
                      result?.testSuiteId,
                      result.id
                    );
                  } catch (_) {
                    setCustomAlertState({
                      open: true,
                      type: "error",
                      title: "Something went wrong",
                      description:
                        "An error occurred while canceling the test execution",
                    });
                  }
                }}
              >
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          )}
      </div>
    </>
  );
};

const ReportRowFailed = ({ index, result }: TestRunRow) => {
  return (
    <div
      key={index}
      className={`relative grid grid-cols-10 text-black border mx-3 p-3 rounded-lg bg-opacity-10 border-[#FF4C4C] bg-[#FF4C4C]`}
    >
      <div className="absolute -top-2 -left-2 bg-red-500 rounded-full w-6 h-6 flex items-center justify-center shadow-md text-white">
        <ExclamationTriangleIcon strokeWidth={1} className="h-4 w-4" />
      </div>
      <div className="col-span-1 text-red-500 pl-2">Failed</div>
      <ReportRowBody result={result} />
    </div>
  );
};

const ReportRowSucceeded = ({ index, result }: TestRunRow) => {
  return (
    <div
      key={index}
      className={`relative grid grid-cols-10 text-black border mx-3 p-3 rounded-lg bg-[#FCFCFC]`}
    >
      <div className="absolute -top-2 -left-2 rounded-full w-6 h-6 flex items-center justify-center bg-[#FCFCFC] shadow-md text-green">
        {/* <CheckCircleIcon strokeWidth={1} className="h-4 w-4" /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-green-500"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
          <polyline points="22 4 12 14.01 9 11.01" />
        </svg>
      </div>
      <div className="col-span-1 text-green-500 pl-2">Succeeded</div>
      <ReportRowBody result={result} />
    </div>
  );
};

const ReportRowRunning = ({ index, result }: TestRunRow) => {
  return (
    <>
      <div
        key={index}
        className={`relative grid grid-cols-10 text-black border mx-3 p-3 rounded-lg bg-[#E2E2E2]`}
      >
        <div className="absolute -top-2 -left-2 rounded-full w-6 h-6 flex items-center justify-center bg-[#FCFCFC] shadow-md text-[#7C7C7C]">
          <ArrowPathIcon strokeWidth={1} className="h-4 w-4" />
        </div>
        <div className="col-span-1 text-[#7C7C7C] pl-2">
          <i>in progress...</i>
        </div>
        <ReportRowBody result={result} />
      </div>
    </>
  );
};

const ReportRowScheduled = ({ index, result }: ReportRow) => {
  return (
    <div
      key={index}
      className={`relative grid grid-cols-10 items-center  border mx-3 p-3 rounded-lg bg-[#C4C4C4] text-[#7C7C7C]`}
    >
      <div className="absolute -top-2 -left-2 rounded-full w-6 h-6 flex items-center justify-center bg-[#FCFCFC] shadow-md text-[#7C7C7C]">
        <ClockIcon strokeWidth={1} className="h-4 w-4" />
      </div>
      <div className="col-span-1 text-[#7C7C7C] pl-2">Scheduled</div>
      <ReportRowBody result={result} />
    </div>
  );
};

const ReportRowBlocked = ({ index, result }: TestRunRow) => {
  return (
    <div
      key={index}
      className={`relative grid grid-cols-10 text-black border mx-3 p-3 rounded-lg bg-opacity-10 border-orange-400 bg-orange-200`}
    >
      <div className="absolute -top-2 -left-2 bg-orange-500 rounded-full w-6 h-6 flex items-center justify-center shadow-md text-white">
        <ExclamationTriangleIcon strokeWidth={1} className="h-4 w-4" />
      </div>
      <div className="col-span-1 text-orange-500 pl-2">Blocked</div>
      <ReportRowBody result={result} />
    </div>
  );
};

const ReportRowCancelled = ({ index, result }: TestRunRow) => {
  return (
    <div
      key={index}
      className={`relative grid grid-cols-10 text-black border mx-3 p-3 rounded-lg bg-opacity-10 border-gray-600 bg-gray-400`}
    >
      <div className="absolute -top-2 -left-2 bg-gray-600 rounded-full w-6 h-6 flex items-center justify-center shadow-md text-white">
        <XCircleIcon strokeWidth={1} className="h-4 w-4" />
      </div>
      <div className="col-span-1 text-gray-700 pl-2">Cancelled</div>
      <ReportRowBody result={result} />
    </div>
  );
};

const ReportDetailNew = ({ succeed, results }: ReportDetailProps) => {
  return (
    <>
      {results.map((result, index) => {
        if (result.status === "failed") {
          return <ReportRowFailed index={index} result={result} />;
        } else if (result.status === "succeeded") {
          return <ReportRowSucceeded index={index} result={result} />;
        } else if (result.status === "running") {
          return <ReportRowRunning index={index} result={result} />;
        } else if (result.status === "initial") {
          return <ReportRowScheduled index={index} result={result} />;
        } else if (result.status === "blocked") {
          return <ReportRowBlocked index={index} result={result} />;
        } else if (result.status === "cancelled") {
          return <ReportRowCancelled index={index} result={result} />;
        }
      })}
    </>
  );
};

export default ReportsPage;
