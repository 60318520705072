import { Dialog, DialogContent } from "@mui/material";
import { atom, useAtom } from "jotai";

interface ZoomImageDialogState {
  open: boolean;
  imageSrc?: string;
}

const zoomImageDialogStateAtom = atom<ZoomImageDialogState>({
  open: false,
});

const ZoomImageDialog = () => {
  const [zoomImageDialogState, setZoomImageDialogState] = useAtom(
    zoomImageDialogStateAtom
  );
  return (
    <Dialog
      open={zoomImageDialogState.open}
      maxWidth={"lg"}
      onClose={() => setZoomImageDialogState({ open: false })}
    >
      <DialogContent
        dividers
        sx={{
          padding: 0,
          // overflow: "hidden",
          // height: "calc(100% - 65px)",
          // padding: 0,
        }}
      >
        <img src={zoomImageDialogState.imageSrc} />
      </DialogContent>
    </Dialog>
  );
};

export default ZoomImageDialog;
export { zoomImageDialogStateAtom };
