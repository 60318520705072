import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { type Crop } from "react-image-crop";
import { object, string } from "yup";
import { Form, Formik } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import { PixelCrop } from "react-image-crop";
import Button from "@mui/material/Button";
import {
  delay,
  getBase64ImageResolution,
  trackSentryException,
} from "../utils/helpers";
import {
  addTemplateToFirestoreCompanyDoc,
  sendTemplateToFirebaseStorage,
} from "../utils/firebase_operations";
import { useQueryClient } from "@tanstack/react-query";
import { atom, useAtom, useSetAtom } from "jotai";
import { customAlertStateAtom } from "./custom-alert";
import FormikTextInput from "../formikComponents/formik-text-input";
import { CompanyData } from "../utils/types";

const TO_RADIANS = Math.PI / 180;
const defaultCropState: Crop = {
  unit: "px",
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

interface CropScreenshotDialogState {
  open: boolean;
  imageSrc?: string;
  companyData?: CompanyData;
  deviceName?: string;
}

const CropScreenshotDialogStateAtom = atom<CropScreenshotDialogState>({
  open: false,
});

const CropScreenshotDialog = () => {
  const [cropScreenshotDialogState, setCropScreenshotDialogState] = useAtom(
    CropScreenshotDialogStateAtom
  );

  const [crop, setCrop] = useState<Crop>(defaultCropState);
  const handleClose = useCallback((resetForm: Function) => {
    resetForm();
    setCrop(defaultCropState);
    setCropScreenshotDialogState({ open: false });
  }, []);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [displayingError, setDisplayingError] = useState(false);
  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const formRef = useRef(null);

  const queryClient = useQueryClient();

  const _submit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const companyData = cropScreenshotDialogState.companyData!;
        const deviceName = cropScreenshotDialogState.deviceName!;
        const imageSrc = cropScreenshotDialogState.imageSrc!;

        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        if (!image || !previewCanvas || !crop) {
          throw new Error("Crop canvas does not exist");
        }

        const offscreen = new OffscreenCanvas(crop.width, crop.height);
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
          throw new Error("No 2d context");
        }

        ctx.drawImage(
          previewCanvas,
          0,
          0,
          previewCanvas.width,
          previewCanvas.height,
          0,
          0,
          offscreen.width,
          offscreen.height
        );
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
          type: "image/png",
          quality: 1,
        });

        const currentTimestamp = Math.ceil(Date.now() / 1000);
        const screenshotResolution = await getBase64ImageResolution(imageSrc);

        const parsedLabel = `${Math.ceil(
          screenshotResolution.width
        )}x${Math.ceil(screenshotResolution.height)}.jpeg`;

        const file = new File([blob], parsedLabel, {
          lastModified: currentTimestamp,
        });

        const imageUrl = await sendTemplateToFirebaseStorage(
          companyData.name,
          parsedLabel,
          file
        );

        const uploadSuccessful = await addTemplateToFirestoreCompanyDoc(
          companyData.name,
          imageUrl,
          values.iconLabel,
          deviceName
        );

        if (uploadSuccessful) {
          await queryClient.setQueryData(
            ["getCompanyData", companyData.organisationId],
            (oldData: any) => {
              const oldTemplates = oldData?.templates ?? [];

              console.log({ oldData, oldTemplates });

              return {
                ...oldData,
                templates: [
                  ...oldTemplates,
                  {
                    label: values.iconLabel,
                    imageUrl: imageUrl,
                    device: deviceName,
                  },
                ],
              };
            }
          );

          setSubmitting(false);
          setCustomAlertState({
            open: true,
            type: "success",
            title: "Success",
            description: `Successfully created new templated "${values.iconLabel}"`,
          });
          handleClose(resetForm);
        } else {
          console.error("unexpected error");
          setDisplayingError(true);
          setSubmitting(false);
          await delay(3000);
          setDisplayingError(false);
        }
      } catch (e) {
        console.error(e);
        trackSentryException(e);
        setDisplayingError(true);
        setSubmitting(false);
        await delay(3000);
        setDisplayingError(false);
      }
    },
    [cropScreenshotDialogState, crop, queryClient]
  );

  return (
    <Dialog
      open={cropScreenshotDialogState.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose(() => {
            formRef.current?.resetForm();
          });
        }
      }}
      maxWidth={"xl"}
      fullWidth
      disableEscapeKeyDown
      sx={{ ".MuiDialog-paper": { height: "calc(100% - 64px)" } }}
    >
      <DialogTitle
        sx={{
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden",
        }}
      >
        Create a template - device:{" "}
        {cropScreenshotDialogState.deviceName ?? "unknown"}
      </DialogTitle>
      <Formik
        innerRef={formRef}
        initialValues={{ iconLabel: "" }}
        validationSchema={object().shape({
          iconLabel: string().required("Required"),
        })}
        onSubmit={_submit}
        validateOnBlur={false}
      >
        {({ isSubmitting, resetForm, isValid }) => (
          <DialogContent
            dividers
            sx={{
              overflow: "hidden",
              height: "calc(100% - 65px)",
              padding: 0,
            }}
          >
            <Form className={"h-full"}>
              <div
                className={
                  "relative flex gap-4 h-[calc(100%_-_72px)] overflow-auto items-start border-b border-b-[#0000001f] py-6 px-6"
                }
              >
                <div
                  className={
                    "flex flex-[10_0_0%] h-full min-w-0 justify-center"
                  }
                >
                  <ReactCrop
                    className={
                      "h-full border-dashed border-4 rounded-sm !overflow-auto"
                    }
                    crop={crop}
                    onChange={(c) => {
                      setCrop(c);
                      canvasPreview(
                        imgRef.current,
                        previewCanvasRef.current,
                        c,
                        1,
                        0
                      );
                    }}
                    onComplete={(c) => {}}
                  >
                    <img
                      className={"object-none !max-w-none"}
                      src={cropScreenshotDialogState.imageSrc}
                      ref={imgRef}
                      alt={"Image to be cropped"}
                    />
                  </ReactCrop>
                </div>
                <div
                  className={
                    "flex flex-col flex-[3_0_0%] justify-evenly w-full items-center mt-2 gap-5"
                  }
                >
                  <div className={"flex flex-col gap-1.5"}>
                    <p className={"text-xl"}>Preview</p>
                    <div className={"relative"}>
                      <canvas
                        ref={previewCanvasRef}
                        className={
                          "border-dashed border-2 h-[195px] w-[195px] object-contain"
                        }
                      />
                    </div>
                  </div>
                  <FormikTextInput
                    label="label"
                    name="iconLabel"
                    type="text"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </div>
                {displayingError && (
                  <div
                    className={
                      "absolute w-full flex flex-col h-[calc(100%_-_24px)] bg-white z-20 items-center justify-center "
                    }
                  >
                    <p className={"text-2xl"}>Error</p>
                    <p className={"text-lg"}>
                      There was an error while trying to upload template, please
                      try again later
                    </p>
                  </div>
                )}
              </div>
              <DialogActions
                className={
                  "flex-shrink-0 flex flex-row justify-end self-end h-[72px] mx-3"
                }
              >
                <Button
                  onClick={() => {
                    handleClose(resetForm);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type={"submit"}
                  startIcon={<CloudUploadIcon />}
                  loading={isSubmitting}
                  disabled={
                    displayingError ||
                    (crop?.width ?? 0) === 0 ||
                    (crop?.height ?? 0) === 0
                  }
                  loadingPosition={"start"}
                >
                  <span>Save</span>
                </LoadingButton>
              </DialogActions>
            </Form>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

const canvasPreview = async (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) => {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};

export default CropScreenshotDialog;
export { CropScreenshotDialogStateAtom };
