import {
  CompanyData,
  Session as SessionType,
  SessionLog,
} from "../../utils/types";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { CircularProgress, Paper, ThemeProvider, Tooltip } from "@mui/material";
import ReasoningAndCommands from "../../components/reasoningAndCommands";
import { styled as materialStyled } from "@mui/material/styles";
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { annotateScreenshot, imageUrlToBase64 } from "../../utils/helpers";
import { DateTime } from "luxon";
import CropScreenshotDialog, {
  CropScreenshotDialogStateAtom,
} from "../../components/cropScreenshotDialog";
import { AuthContext } from "../../auth/AuthContext";
import { useAtomValue } from "jotai/index";
import { companyDataAtom } from "../test-run/test-run-atoms";
import { CameraAlt } from "@mui/icons-material";
import {
  LogDetailDialog,
  logDetailDialogStateAtom,
} from "../../components/test-editor-gpt/execution-logs/logDetailDialog";
import { useSetAtom } from "jotai";
import { darkTheme } from "../../themes";

const Content = ({ session }: { session: SessionType }) => {
  const { user } = useContext<any>(AuthContext);

  const { data: companyData } = useAtomValue(companyDataAtom(user.companyId));

  const parsedEnvVars = useMemo(
    () =>
      companyData?.settings.envVars?.map((envVar) => `env.${envVar.key}`) ?? [],
    [companyData?.settings]
  );

  const acceptedDynamicValues = useMemo(
    () => [
      "timestamp",
      "currentDate",
      "currentDateFormatted",
      ...parsedEnvVars,
    ],
    [parsedEnvVars]
  );

  return (
    <ContentWrapper variant={"outlined"}>
      {companyData != null && (
        <ThemeProvider theme={darkTheme}>
          <LogDetailDialog
            companyData={companyData}
            acceptedDynamicValues={acceptedDynamicValues}
            platform={session.deviceConfig.platform}
          />
        </ThemeProvider>
      )}
      <CropScreenshotDialog />
      {session.interactionLogs.map((log) => (
        <LogItem log={log} companyData={companyData!} session={session} />
      ))}
    </ContentWrapper>
  );
};

const LogItem = ({
  log,
  companyData,
  session,
}: {
  log: SessionLog;
  companyData: CompanyData;
  session: SessionType;
}) => {
  const setLogDetailDialogState = useSetAtom(logDetailDialogStateAtom);

  const annotatedBaselineData = useQuery({
    queryKey: ["annotateSessionScreenshot", log.screenshotUrl],
    queryFn: async () => {
      try {
        const image = await imageUrlToBase64(log.screenshotUrl);
        const nonAnnotatedScreenshot = image as string;

        const annotatedImage = await annotateScreenshot(
          nonAnnotatedScreenshot,
          (log.appetizeCommands?.at(0) ?? "").split("\n")
        );

        return { annotatedImage, nonAnnotatedScreenshot };
      } catch (e) {
        console.log(e);
      }
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const clickOnLogHandler = useCallback(
    (log: SessionLog) => {
      setLogDetailDialogState({
        open: true,
        isBaseline: false,
        redefiningStep: true,
        sessionLog: {
          ...log,
          id: Date.now(),
          screenshotUrl:
            annotatedBaselineData.data?.annotatedImage ?? log.screenshotUrl,
        },
      });
    },
    [annotatedBaselineData.data]
  );

  const setCropScreenshotDialogState = useSetAtom(
    CropScreenshotDialogStateAtom
  );

  const setScreenshot = useCallback(() => {
    setCropScreenshotDialogState({
      open: true,
      imageSrc: annotatedBaselineData.data!.nonAnnotatedScreenshot!,
      companyData: companyData!,
      deviceName: session.deviceConfig.device ?? "--",
    });
  }, [
    annotatedBaselineData.data?.nonAnnotatedScreenshot,
    session,
    companyData,
  ]);

  const promptActionHandler = useCallback(() => {
    clickOnLogHandler(log);
  }, [log]);

  return (
    <LogItemWrapper>
      <ReasoningAndCommands
        promptActionHandler={promptActionHandler}
        stepDescription={log.prompt}
        fromCache={log.fromCache}
        reasoning={log.reasoning}
        absoluteTimestamp={DateTime.fromISO(
          `${log.timestamp.slice(0, 23)}+02:00`
        )
          .toLocal()
          .toMillis()}
        commands={log.gptCommands.join("\n")}
      />
      <Screenshot
        setScreenshot={setScreenshot}
        nonAnnotatedImage={
          annotatedBaselineData.data?.nonAnnotatedScreenshot ??
          log.screenshotUrl
        }
        annotatedImage={annotatedBaselineData.data?.annotatedImage}
      />
    </LogItemWrapper>
  );
};

const Screenshot = ({
  annotatedImage,
  nonAnnotatedImage,
  setScreenshot,
}: {
  annotatedImage?: string;
  nonAnnotatedImage: string;
  setScreenshot: any;
}) => {
  return (
    <ScreenshotWrapper>
      {nonAnnotatedImage && (
        <Tooltip title={"Create a template"} placement="left">
          <ActionButton onClick={setScreenshot}>
            <CameraAlt />
          </ActionButton>
        </Tooltip>
      )}
      <img src={annotatedImage ?? nonAnnotatedImage} />
    </ScreenshotWrapper>
  );
};

const ActionButton = styled.button`
  position: absolute;
  left: -24px;
  color: white;
  padding: 16px;
  border-radius: 9999px;
  background-color: #4c63ff;

  &:disabled {
    color: #455a64;
    cursor: not-allowed;
    background-color: #bdbdbd;
  }

  &:hover {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }
`;

const ContentWrapper = materialStyled(Paper)(() => ({
  backgroundColor: "rgb(232, 232, 232)",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  padding: "16px",
  gap: "16px",
}));

const ScreenshotWrapper = styled.div`
  grid-area: screenshot;
  position: relative;
`;

const LogItemWrapper = materialStyled(Paper)(() => ({
  display: "grid",
  gridTemplateAreas: '"info screenshot"',
  gridTemplateColumns: "1fr 25%",
  gap: "24px",
  padding: "16px",
}));

export default Content;
