import { InteractionLog, TestSuiteRecordingApp } from "../../utils/types";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled as styledMaterial } from "@mui/material/styles";
import { TabPanel } from "@mui/lab";
import {
  annotateScreenshot,
  getBase64FromImageUrl,
  getBase64ImageResolution,
  parseGptCommandsResponse,
} from "../../utils/helpers";
import { useSetAtom } from "jotai/index";
import { logDetailDialogStateAtom } from "../../components/test-editor-gpt/execution-logs/logDetailDialog";
import ReasoningAndCommands from "../../components/reasoningAndCommands";
import { IconButton, Paper, ThemeProvider, Tooltip, Zoom } from "@mui/material";
import styled from "@emotion/styled";
import { AutoSizer, CellMeasurer } from "react-virtualized";
import { interactionLogsCache, CustomFab, CustomList } from "./components";
import { CameraAlt, North, South, ZoomIn } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { forceMoveToTimestampNetworkTabAtom } from "./networkLogsTab";
import { forceMoveToTimestampDeviceTabAtom } from "./consoleLogsTab";
import CropScreenshotDialog, {
  CropScreenshotDialogStateAtom,
} from "../../components/cropScreenshotDialog";
import ZoomImageDialog, {
  zoomImageDialogStateAtom,
} from "../../components/zoomImageDialog";
import { lightTheme } from "../../themes";
import { AuthContext } from "../../auth/AuthContext";
import { companyDataAtom } from "../test-run/test-run-atoms";
import { useAtomValue } from "jotai";
import { useLoaderData } from "react-router-dom";

interface InteractionLogsTabProps {
  interactionLogs: InteractionLog[];
  playbackTimestamp: number;
  display: boolean;
  setTabValue: Dispatch<SetStateAction<string>>;
}

const InteractionLogsTab = ({
  interactionLogs,
  playbackTimestamp,
  display,
  setTabValue,
}: InteractionLogsTabProps) => {
  const listRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [jumpToTimestamp, setJumpToTimestamp] = useState<
    "hidden" | "top" | "bottom"
  >("hidden");
  const [scrollToIndex, setScrollToIndex] = useState<undefined | number>(
    undefined
  );

  useEffect(() => {
    if (
      interactionLogs?.at(0)?.relativeTimestamp != null &&
      playbackTimestamp <= interactionLogs?.at(0)?.relativeTimestamp!
    ) {
      setJumpToTimestamp("hidden");
    } else {
      const startIndex = listRef.current?.Grid._renderedRowStartIndex;
      const stopIndex = listRef.current?.Grid._renderedRowStopIndex;

      const someLogRenderedBeforeCurrentTimestamp = interactionLogs
        ?.slice(startIndex, stopIndex + 1)
        ?.some((log) => log.relativeTimestamp <= playbackTimestamp);

      const someLogRenderedAfterCurrentTimestamp = interactionLogs
        ?.slice(startIndex, stopIndex + 1)
        ?.some((log) => log.relativeTimestamp > playbackTimestamp);

      const lastItemDisplayed = stopIndex === interactionLogs?.length - 1;
      if (
        (someLogRenderedBeforeCurrentTimestamp &&
          someLogRenderedAfterCurrentTimestamp) ||
        (playbackTimestamp > interactionLogs?.at(-1)?.relativeTimestamp &&
          lastItemDisplayed)
      ) {
        setJumpToTimestamp("hidden");
      } else {
        const everyLogRenderedBeforeCurrentTimestamp = interactionLogs
          ?.slice(startIndex, stopIndex + 1)
          ?.every((log) => log.relativeTimestamp <= playbackTimestamp);
        setJumpToTimestamp(
          everyLogRenderedBeforeCurrentTimestamp ? "bottom" : "top"
        );
        setScrollToIndex(undefined);
      }
    }
  }, [playbackTimestamp, interactionLogs, scrollTop]);

  const jumpToTimestampHandler = useCallback(() => {
    const indexToJump = interactionLogs?.findLastIndex(
      (log) => playbackTimestamp >= log.relativeTimestamp
    );

    const extraItemExist = !!interactionLogs[indexToJump + 1];

    setScrollToIndex(
      jumpToTimestamp === "bottom"
        ? indexToJump + (extraItemExist ? 1 : 0)
        : indexToJump
    );
  }, [interactionLogs, playbackTimestamp, jumpToTimestamp]);

  const setForceMoveToTimestampNetworkTab = useSetAtom(
    forceMoveToTimestampNetworkTabAtom
  );

  const setForceMoveToTimestampDeviceTab = useSetAtom(
    forceMoveToTimestampDeviceTabAtom
  );

  const handleMoveToNetworkLog = useCallback((timestamp: number) => {
    setTabValue("1");
    setForceMoveToTimestampNetworkTab(timestamp);
  }, []);

  const handleMoveToDeviceLog = useCallback((timestamp: number) => {
    setTabValue("2");
    setForceMoveToTimestampDeviceTab(timestamp);
  }, []);

  return (
    <InteractionLogsWrapper keepMounted value={"0"} display={display}>
      <Zoom in={jumpToTimestamp != "hidden"} unmountOnExit>
        <CustomFab
          variant={"extended"}
          jumpToTimestamp={jumpToTimestamp}
          onClick={jumpToTimestampHandler}
        >
          {jumpToTimestamp !== "top" ? <South /> : <North />}
          Jump to current timestamp
        </CustomFab>
      </Zoom>
      <AutoSizer>
        {({ height, width }) => (
          <CustomList
            onScroll={(params) => {
              setScrollTop(params.scrollTop);
            }}
            ref={listRef}
            rowCount={interactionLogs.length}
            height={height}
            width={width}
            deferredMeasurementCache={interactionLogsCache}
            rowHeight={interactionLogsCache.rowHeight}
            overscanRowCount={3}
            scrollToAlignment="start"
            scrollToIndex={scrollToIndex}
            rowRenderer={({ index, key, style, parent }) => {
              const currentLog = interactionLogs[index];
              const className =
                index === interactionLogs.length - 1 &&
                playbackTimestamp > currentLog.relativeTimestamp
                  ? "afterLastItem"
                  : currentLog.relativeTimestamp <= playbackTimestamp
                  ? "beforeCurrentTime"
                  : "afterCurrentTime";

              return (
                <CellMeasurer
                  key={key}
                  cache={interactionLogsCache}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ registerChild }) => (
                    <div
                      className={className}
                      style={{
                        ...style,
                        paddingBottom: "16px",
                        paddingTop: index === 0 ? "15.5px" : 0,
                      }}
                      ref={registerChild}
                      key={`consoleLogRows-${index}`}
                    >
                      <InteractionLogItems
                        index={index}
                        listRef={listRef}
                        handleMoveToNetworkLog={handleMoveToNetworkLog}
                        key={`interactionLog-${index}`}
                        interaction={currentLog}
                        playbackTimestamp={playbackTimestamp}
                        handleMoveToDeviceLog={handleMoveToDeviceLog}
                      />
                    </div>
                  )}
                </CellMeasurer>
              );
            }}
          />
        )}
      </AutoSizer>
    </InteractionLogsWrapper>
  );
};

const InteractionLogsWrapper = styledMaterial(TabPanel)(
  ({ display }: { display: boolean }) =>
    display
      ? {
          gridArea: "tab-content",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          overflow: "auto",
          position: "relative",
          "& .beforeCurrentTime + .afterCurrentTime::before": {
            borderTop: "3px solid #90caf9",
            content: '""',
            left: "0px",
            position: "absolute",
            top: "0px",
            width: "100%",
          },
        }
      : {}
);

function InteractionLogItems({
  interaction,
  playbackTimestamp,
  handleMoveToNetworkLog,
  handleMoveToDeviceLog,
  listRef,
  index,
}: {
  interaction: InteractionLog;
  playbackTimestamp: number;
  handleMoveToNetworkLog: (timestamp: number) => void;
  handleMoveToDeviceLog: (timestamp: number) => void;
  listRef: React.MutableRefObject<any>;
  index: number;
}) {
  const { data: parsedScreenshot } = useQuery({
    queryKey: ["annotateScreenshotRecording", interaction.screenshot],
    queryFn: async () => {
      const parsedCommands = parseGptCommandsResponse(
        interaction.gptCommands ?? []
      ).commands;

      const nonAnnotatedScreenshot = `data:image/jpeg;base64,${await getBase64FromImageUrl(
        interaction.screenshot!
      )}`;

      const annotatedScreenshot = await annotateScreenshot(
        interaction.screenshot!,
        parsedCommands.split("\n")
      );

      return { nonAnnotatedScreenshot, annotatedScreenshot };
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const processedScreenshot =
    parsedScreenshot?.annotatedScreenshot ?? interaction.screenshot;

  const setLogDetailDialogState = useSetAtom(logDetailDialogStateAtom);

  const { reasoning, commands, step_number, fromCache, step_description } =
    parseGptCommandsResponse(interaction.gptCommands ?? []);

  const className =
    interaction.relativeTimestamp <= playbackTimestamp
      ? "beforeCurrentTime"
      : "afterCurrentTime";

  const onClickHandler = useCallback(() => {
    if (interaction.serverExecutionParams != null) {
      const { reasoning, commands, step_number, fromCache } =
        parseGptCommandsResponse(interaction.gptCommands!);

      setLogDetailDialogState({
        open: true,
        isBaseline: false,
        redefiningStep: true,
        log: {
          annotatedScreenshotUrl: processedScreenshot,
          screenshotUrl: interaction.screenshot!,
          appetizeCommand: "",
          gptCommand: commands,
          serverExecutionParams: interaction.serverExecutionParams,
          reasoning,
          stepNumber: step_number,
          fromCache: fromCache,
        },
      });
    } else if (interaction.preRequestResponse != null) {
      setLogDetailDialogState({
        open: true,
        isBaseline: false,
        redefiningStep: false,
        log: {
          annotatedScreenshotUrl: processedScreenshot,
          screenshotUrl: interaction.screenshot!,
          appetizeCommand: "Executed network request",
          prompt: "Executing network request",
          gptCommand: interaction.preRequestResponse.curl,
          preRequestResponse: JSON.stringify(
            interaction.preRequestResponse.data,
            null,
            1
          ),
          statusCode: interaction.preRequestResponse.status,
          fromCache: false,
          title: "pre-requests",
        },
      });
    }
  }, [interaction, processedScreenshot]);

  const setZoomImageDialogState = useSetAtom(zoomImageDialogStateAtom);
  const useAuth = () => useContext<any>(AuthContext);
  const { user } = useAuth();

  const handleImageLoad = useCallback(() => {
    if (!listRef.current) return;

    // Use rAF to batch height updates
    requestAnimationFrame(() => {
      // Get current scroll position
      const currentScrollTop =
        listRef.current.Grid._scrollingContainer.scrollTop;

      // Clear the cache for this specific row
      interactionLogsCache.clear(index, 0);

      // Recompute heights and restore scroll position
      listRef.current.recomputeRowHeights(index);

      // Only force update if we're not already in a scroll operation
      if (!listRef.current.Grid._isScrolling) {
        listRef.current.forceUpdate();
      }

      // Restore scroll position if needed
      if (currentScrollTop > 0) {
        listRef.current.Grid._scrollingContainer.scrollTop = currentScrollTop;
      }
    });
  }, [index]);

  return (
    <InteractionLogItemWrapper className={className} elevation={2}>
      <ThemeProvider theme={lightTheme}>
        <ZoomImageDialog />
        <CropScreenshotDialog />
      </ThemeProvider>
      <InfoWrapper>
        <ReasoningAndCommands
          promptActionHandler={onClickHandler}
          relativeTimestamp={interaction.relativeTimestamp}
          handleMoveToNetworkLog={handleMoveToNetworkLog}
          handleMoveToDeviceLog={handleMoveToDeviceLog}
          reasoning={reasoning
            .replace("reasoning:", "")
            .split(" - ")
            .filter((item) => item)}
          commands={commands}
          stepNumber={step_number}
          fromCache={fromCache}
          stepDescription={step_description}
          absoluteTimestamp={interaction.absoluteTimestamp}
          preRequestResponse={interaction.preRequestResponse}
        />
      </InfoWrapper>
      <ScreenshotWrapper>
        <CustomImage
          onLoad={handleImageLoad}
          src={processedScreenshot}
        ></CustomImage>
        <ActionsRow>
          {!user.firebaseUser.isAnonymous &&
            parsedScreenshot?.nonAnnotatedScreenshot && (
              <CreateTemplateWrapper
                imageSrc={parsedScreenshot!.nonAnnotatedScreenshot}
              />
            )}
          <Tooltip title={"Enlarge image"} placement={"bottom"}>
            <span>
              <CustomActionButton
                aria-label="Enlarge image"
                size="small"
                onClick={(event) => {
                  setZoomImageDialogState({
                    open: true,
                    imageSrc: parsedScreenshot?.nonAnnotatedScreenshot,
                  });
                }}
              >
                <ZoomIn />
              </CustomActionButton>
            </span>
          </Tooltip>
        </ActionsRow>
      </ScreenshotWrapper>
    </InteractionLogItemWrapper>
  );
}

const CreateTemplateWrapper = ({ imageSrc }: { imageSrc: string }) => {
  const loaderData = useLoaderData() as TestSuiteRecordingApp;
  const { data: testSuiteData } = useAtomValue(loaderData.testSuiteDataAtom);
  const { data: testRunData } = useAtomValue(loaderData.testRunDataAtom);
  const { data: companyData } = useAtomValue(
    companyDataAtom(testSuiteData!.organisationId)
  );

  const setCropScreenshotDialogState = useSetAtom(
    CropScreenshotDialogStateAtom
  );

  return (
    <>
      {companyData != null && (
        <Tooltip title={"Create template"} placement={"bottom"}>
          <span>
            <CustomActionButton
              aria-label="create template"
              size="small"
              onClick={(event) => {
                setCropScreenshotDialogState({
                  open: true,
                  imageSrc,
                  companyData: companyData!,
                  deviceName: testRunData!.runs!.at(0)!.device,
                });
              }}
            >
              <CameraAlt />
            </CustomActionButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

const CustomActionButton = styled(IconButton)(() => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  transition: "opacity 300ms",
}));

const InteractionLogItemWrapper = styledMaterial(Paper)(() => ({
  display: "grid",
  gridTemplateAreas: '"info screenshot"',
  gridTemplateColumns: "minmax(0,1fr) 240px",
  gap: "24px",
  padding: "16px",
  position: "relative",
  height: "100%",
}));

const CustomImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  min-height: 400px;
  width: auto;
  height: auto;
`;

const InfoWrapper = styled.p`
  grid-area: info;
`;

const ScreenshotWrapper = styled.div`
  grid-area: screenshot;
  position: relative;
  overflow: hidden;

  &:hover button {
    opacity: 1;
  }

  & button {
    opacity: 0;
  }
`;

//transform to object
const ActionsRow = styled.div`
  visibility: visible;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  top: 4px;
  right: 4px;
  width: 100%;
`;

export default InteractionLogsTab;
