import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  BuildData,
  BuildLogs,
  CachedScreenData,
  CompanyData,
  EnvVar,
  InteractionLog,
  PromptStep,
  RunExecutionData,
  ServerExecutionParams,
  Test,
} from "../../utils/types";
import { useAtom, useSetAtom } from "jotai";
import { customFirestore, customStorage } from "../../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import {
  annotateScreenshot,
  b64toBlob,
  delay,
  getPromptText,
  hideEnvVarSecrets,
  hideNetworkLogsEnvVars,
  parseGptCommandsResponse,
  replaceItemFomArray,
  trackAmplitudeEvent,
  trackSentryException,
  uploadScreenshotToStorage,
} from "../../utils/helpers";
import { addDoc, updateDoc } from "@firebase/firestore";
import { collection, doc } from "firebase/firestore";
import { buildLogsAtomWithId } from "../../atoms";
import { useStepObservable } from "./use-step-observable";
import JSZip from "jszip";
import { RESET } from "jotai/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { testBuilderCustomAlertStateAtom } from "../test-builder-page";
import { AuthContext } from "../../auth/AuthContext";
import { useAtomValue } from "jotai/index";
import { StorageReference } from "@firebase/storage";
import { sessionMutationAtom, SimulatorSession } from "../../utils/simulator";

interface ExecutionStepState {
  image: string;
  gptCommands: string[];
  appetizeCommands: string[];
  actionHistory: string[];
  screenContent: string[];
  serverExecutionParams?: ServerExecutionParams;
}

interface RunState {
  id?: string;
  startedTimeInEpoch?: number;
  testId?: string;
  title: string;
  commands: string;
  promptSteps: PromptStep[];
  executionSteps: ExecutionStepState[];
  preRequests?: {
    image?: string;
    items?: { curl: string; status: number; data: any }[];
  };
}

interface ExecutionState {
  failedExecutions: ExecutionState[];
  isRunning: boolean;
  runs: RunState[];
  result?: string;
  resultContent?: string;
}

const defaultExecutionState: ExecutionState = {
  failedExecutions: [],
  isRunning: false,
  runs: [],
};

const useExecuteSteps = ({
  buildData,
  companyData,
  testSuiteId,
}: {
  companyData: CompanyData;
  buildData: BuildData;
  testSuiteId?: string;
}) => {
  const [executionState, setExecutionState] = useState<ExecutionState>(
    defaultExecutionState
  );
  const [parsedExecutionLogs, setParsedExecutionLogs] = useState<
    CachedScreenData[]
  >([]);

  const buildLogsAtom = buildLogsAtomWithId(buildData.id);
  const [buildLogsData, setBuildLogsData] = useAtom(buildLogsAtom);
  const settingsData = companyData.settings;
  const setTestBuilderCustomAlertState = useSetAtom(
    testBuilderCustomAlertStateAtom
  );
  const createSessionMutation = useAtomValue(sessionMutationAtom);

  const appetizeCloneSessionRef = useRef<SimulatorSession | undefined>(
    createSessionMutation.data
  );

  useEffect(() => {
    if (companyData.settings.enableAppetizeClone) {
      appetizeCloneSessionRef.current = createSessionMutation.data;
    }
  }, [
    testSuiteId,
    createSessionMutation.data,
    companyData.settings.enableAppetizeClone,
  ]);

  useEffect(() => {
    const onBeforeUnload = (ev: Event) => {
      console.log("will call stop onBeforeUnload");
      appetizeCloneSessionRef.current?.stop();
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      console.log("will call stop");
      appetizeCloneSessionRef.current?.stop();
    };
  }, []);

  const queryClient = useQueryClient();

  const { createObservables } = useStepObservable({
    fromEditor: testSuiteId == undefined,
    buildData,
    companyData,
  });

  const { user } = useContext<any>(AuthContext);

  const [stopExecutionHandler, setStopExecutionHandler] = useState<
    (() => void) | null
  >(null);

  const interactionLogs = useRef<InteractionLog[]>([]);

  useEffect(() => {
    interactionLogs.current = buildLogsData?.interactionLogs ?? [];
  }, [buildLogsData.interactionLogs]);

  const concludedParentRunMutation = useMutation({
    mutationFn: async ({
      status,
      statusMessage,
      run,
      executionState,
    }: {
      status: string;
      statusMessage: string;
      run: RunExecutionData;
      executionState?: ExecutionState;
    }) => {
      const date = new Date();
      const finishedTimeInEpoch = Math.ceil(date.getTime() / 1000);
      const envVars: EnvVar[] = settingsData.envVars ?? [];

      let videoRef: StorageReference | undefined;
      let generatedZipFile: Blob | undefined;
      const usingAppetizeClone = companyData.settings.enableAppetizeClone;

      if (usingAppetizeClone) {
        try {
          await appetizeCloneSessionRef.current?.stop();
          await delay(10000);
        } catch (e) {}
      } else {
        const zip = new JSZip();
        const getDifferenceArray = (arr) => {
          const differences = [arr[0].relativeTimestamp]; // First number as it is

          for (let i = 1; i < arr.length; i++) {
            const currentNumber = arr[i].relativeTimestamp;
            const previousNumber = arr[i - 1].relativeTimestamp;
            differences.push(currentNumber - previousNumber);
          }

          return differences;
        };

        const sortedFrames = buildLogsData.videoFrames.sort(
          (frameA, frameB) =>
            frameA.relativeTimestamp - frameB.relativeTimestamp
        );

        const differences =
          sortedFrames.length > 0 ? getDifferenceArray(sortedFrames) : [];

        let fileNames: string[] = [];

        sortedFrames.forEach(function (imageUint8Array, index) {
          const currentFileName = `image-${index}-${differences[index]}.jpeg`;
          fileNames.push(currentFileName);
          zip.file(currentFileName, imageUint8Array.buffer);
        });

        zip.file("files.txt", fileNames.join("\n"));
        generatedZipFile = await zip.generateAsync({ type: "blob" });

        videoRef = ref(
          customStorage,
          `videos/${testSuiteId}/${run.id}/recording.zip`
        );
      }

      try {
        if (videoRef != undefined && generatedZipFile != undefined) {
          await uploadBytes(videoRef, generatedZipFile);
        }

        const logsRef = ref(
          customStorage,
          `logs/${testSuiteId}/${run.id}/logs.json`
        );
        if (buildLogsData.logs.length > 0) {
          await uploadString(
            logsRef,
            JSON.stringify(
              buildLogsData.logs.map((log) => {
                return {
                  ...log,
                  message: hideEnvVarSecrets(log.message, envVars),
                };
              })
            )
          );
        }

        const networkLogsRef = ref(
          customStorage,
          `networkLogs/${testSuiteId}/${run.id}/networkLogs.json`
        );
        if (buildLogsData.networkLogs.length > 0) {
          await uploadString(
            networkLogsRef,
            JSON.stringify(
              hideNetworkLogsEnvVars(buildLogsData.networkLogs, envVars)
            )
          );
        }

        const interactionLogsAux = interactionLogs.current;
        const interactionsLogsRef = ref(
          customStorage,
          `interactionsLogs/${testSuiteId}/${run.id}/interactionsLogs.json`
        );
        if (interactionLogsAux.length > 0) {
          const uploadedScreenshots = await Promise.all(
            interactionLogsAux
              .filter((interactionLog) => interactionLog.screenshot != null)
              .map(async (interactionLog, index) => {
                const base64Screenshot = interactionLog.screenshot;
                const screenshotRef = ref(
                  customStorage,
                  `interactionsLogs/${testSuiteId}/${run.id}/screenshot-${
                    index + 1
                  }.png`
                );
                const screenshotBlob = await b64toBlob(base64Screenshot);
                await uploadBytes(screenshotRef, screenshotBlob);
                return await getDownloadURL(ref(screenshotRef));
              })
          );

          await uploadString(
            interactionsLogsRef,
            JSON.stringify(
              interactionLogsAux
                .filter((interactionLog) => interactionLog.screenshot != null)
                .map<InteractionLog>((interactionsLog, index) => {
                  const { screenshot, ...interactionWithoutBase64Screenshot } =
                    interactionsLog;

                  return {
                    ...{
                      ...interactionWithoutBase64Screenshot,
                      gptCommands:
                        interactionsLog.gptCommands?.map((command: string) =>
                          hideEnvVarSecrets(command, envVars)
                        ) ?? [],
                    },
                    screenshotUrl: uploadedScreenshots[index],
                  };
                })
            )
          );
        }

        try {
          console.log("Adding run doc to the run history collection");
          await addDoc(
            collection(
              customFirestore,
              "custom-tests",
              run.testId!,
              "run-history"
            ),
            {
              startedTimeInEpoch:
                executionState != null
                  ? executionState.runs?.at(-1)?.startedTimeInEpoch ?? 0
                  : finishedTimeInEpoch,
              finishedTimeInEpoch,
              status,
              run_ref: run.id,
              suite_ref: testSuiteId,
            }
          );
        } catch (e) {
          trackSentryException(e);
          console.log("Error occured when saving run history", e);
        }

        await updateDoc(
          doc(
            customFirestore,
            "custom-test-suite-runs",
            testSuiteId!,
            "runs",
            run.id!
          ),
          {
            status: status,
            ...(videoRef != null && { recordingPath: videoRef.fullPath }),
            ...(usingAppetizeClone && {
              tempVideoEndpointUrl: `${appetizeCloneSessionRef.current?.serverUrl}/session_recording/${appetizeCloneSessionRef.current?.info.session_id}`,
            }),
            ...(executionState == null && {
              startedTimeInEpoch: finishedTimeInEpoch,
            }),
            finishedTimeInEpoch,
            statusMessage: statusMessage,
            ...(buildLogsData.logs.length > 0 && {
              logsPath: logsRef.fullPath,
            }),
            ...(buildLogsData.networkLogs.length > 0 && {
              networkLogsPath: networkLogsRef.fullPath,
            }),
            ...(interactionLogsAux.length > 0 && {
              interactionLogsPath: interactionsLogsRef.fullPath,
            }),
          }
        );

        setExecutionState((prev) => ({
          ...prev,
          result: status === "succeeded" ? "Success" : "Failed",
          resultContent: statusMessage,
        }));
      } catch (error) {
        trackSentryException(error);
        console.error(error);
      }
    },
  });

  const concludedDependencyMutation = useMutation({
    mutationFn: async ({
      status,
      run,
      parentRun,
      statusMessage,
      executionState,
    }: {
      status: string;
      run: RunExecutionData;
      parentRun: RunExecutionData;
      statusMessage: string;
      executionState: ExecutionState;
    }) => {
      const date = new Date();
      const finishedTimeInEpoch = Math.ceil(date.getTime() / 1000);

      await updateDoc(
        doc(
          customFirestore,
          "custom-test-suite-runs",
          testSuiteId!,
          "runs",
          parentRun.id!,
          "dependencies",
          run.id!
        ),
        {
          status,
          statusMessage: statusMessage,
          finishedTimeInEpoch,
        }
      );
      if (status !== "succeeded") {
        await concludedParentRunMutation.mutateAsync({
          status,
          run: parentRun,
          statusMessage,
          executionState,
        });
      }
    },
  });

  const startedRunningDependencyMutation = useMutation({
    mutationFn: async ({
      run,
      parentRun,
      startedTimeInEpoch,
    }: {
      run: RunExecutionData;
      parentRun: RunExecutionData;
      startedTimeInEpoch: number;
    }) => {
      await updateDoc(
        doc(
          customFirestore,
          "custom-test-suite-runs",
          testSuiteId!,
          "runs",
          parentRun.id!,
          "dependencies",
          run.id!
        ),
        {
          status: "running",
          startedTimeInEpoch,
        }
      );
    },
  });

  const startedRunningParentRunMutation = useMutation({
    mutationFn: async ({
      run,
      startedTimeInEpoch,
    }: {
      run: RunExecutionData;
      startedTimeInEpoch: number;
    }) => {
      await updateDoc(
        doc(
          customFirestore,
          "custom-test-suite-runs",
          testSuiteId!,
          "runs",
          run.id!
        ),
        {
          status: "running",
          startedTimeInEpoch,
        }
      );
    },
  });

  const saveRunExecutionAsBaseline = useCallback(
    async ({
      parentRunTestId,
      runState,
      platform,
      device,
    }: {
      parentRunTestId: string;
      runState: RunState;
      platform: string;
      device: string;
    }) => {
      console.log("Calling saveRunExecutionAsBaseline with parameters", {
        parentRunTestId,
        runState,
        platform,
        device,
      });

      if (runState.testId == null) {
        console.log("Test ID is missing - not saving");
        return;
      }

      try {
        const cachedScreenData: CachedScreenData[] = [];
        const testRef = doc(customFirestore, "custom-tests", runState.testId);

        const cachePath = `cachedSteps.${platform}.${device}`;
        await updateDoc(testRef, {
          [cachePath]: [],
        });

        for (const step of runState.executionSteps) {
          const {
            reasoning,
            commands,
            step_number,
            step_description,
            actions_description,
            fromCache,
            step_id,
          } = parseGptCommandsResponse(step.gptCommands);

          const stepNumberInt = parseInt(step_number);

          const promptText = getPromptText(
            stepNumberInt,
            runState?.commands,
            step_description,
            step_id,
            runState?.promptSteps ?? []
          );

          console.log("Returned prompt text", { promptText });

          const currentTimestamp = new Date().getTime();
          const screenshotLink = await uploadScreenshotToStorage(
            step.image,
            `cachedScreenshots/${runState.testId}/${platform}-${device}-cachedScreenshot-${currentTimestamp}.jpg`
          );

          cachedScreenData.push({
            screenshotUrl: screenshotLink,
            gptCommand: commands ?? "",
            appetizeCommand: step.appetizeCommands?.join("\n") ?? "",
            stepNumber: step_number ?? "",
            prompt: promptText ?? "",
            reasoning: reasoning ?? "",
            fromCache: fromCache ?? false,
            actionsDescription: actions_description ?? "",
          });
        }

        await updateDoc(testRef, {
          [cachePath]: cachedScreenData,
        });

        console.log(`Successfully saved execution as a new baseline`);

        const fromEditor = testSuiteId == null;
        if (fromEditor) {
          const test: Test | undefined = queryClient.getQueryData([
            "getTestForTestEditor",
            parentRunTestId,
          ]);
          if (test != null) {
            let newData;
            if (parentRunTestId === runState.testId) {
              newData = {
                cachedSteps: {
                  [platform]: {
                    [device]: cachedScreenData,
                  },
                },
              };
            } else {
              newData = {
                localDependenciesCacheSteps: [
                  ...(test.localDependenciesCacheSteps != null
                    ? test.localDependenciesCacheSteps
                    : []),

                  ...cachedScreenData.map((cachedRun) => ({
                    ...cachedRun,
                    title: runState.title,
                  })),
                ],
              };
            }

            const updatedTest = {
              ...test,
              ...newData,
            };

            queryClient.setQueryData(
              ["getTestForTestEditor", parentRunTestId!],
              updatedTest
            );
          }
        }
      } catch (e) {
        trackSentryException(e);
        console.log("Error saving run execution as baseline", e);
      }
    },
    []
  );

  const handleConcludeExecution = useCallback(
    async (
      event: any,
      runs: RunExecutionData[],
      executionState: ExecutionState,
      companyName: string
    ) => {
      const fromEditor = testSuiteId == null;
      const isParentRun = event.data.parentRun == null;

      if (event.data.status === "succeeded") {
        const parentRunTestId = runs.at(-1)!.testId!;
        const isExecutedPartially =
          fromEditor && runs[0]?.isPartialExecution === true;
        const isRunUntilExecution = event.data.run.isRunUntilExecution === true;

        // TODO remove "fromEditor" once fix for saving baseline from cloud run is implemented
        if (fromEditor && !isExecutedPartially && !isRunUntilExecution) {
          await saveRunExecutionAsBaseline({
            parentRunTestId,
            platform: event.data.run.platform,
            device: event.data.run.device,
            runState: executionState.runs.at(-1)!,
          });
        }
      }

      if (fromEditor) {
        if (isParentRun || event.data.status !== "succeeded") {
          let notificationMessage: string | null = null;
          if (event.data.run.isRunUntilExecution === true) {
            notificationMessage = "Run until execution completed";
          }
          if (event.data.status !== "succeeded") {
            notificationMessage = "Test failed";
          }
          if (notificationMessage != null) {
            const notification = new Notification(notificationMessage, {
              body: "The execution concluded",
            });

            notification.onshow = () => {
              alert(notificationMessage);
            };
          }

          setTestBuilderCustomAlertState({
            open: true,
            type: event.data.status === "succeeded" ? "success" : "error",
            title:
              event.data.status === "succeeded"
                ? "Success"
                : "Something went wrong",
            description: event.data.message,
            autoclose: false,
          });

          setExecutionState((prev) => ({
            ...prev,
            result: event.data.status === "succeeded" ? "Success" : "Failed",
            resultContent: event.data.message,
          }));
        }
      } else {
        if (isParentRun) {
          await concludedParentRunMutation.mutateAsync({
            run: event.data.run,
            status: event.data.status,
            statusMessage: event.data.message,
            executionState,
          });
        } else {
          await concludedDependencyMutation.mutateAsync({
            run: event.data.run,
            parentRun: event.data.parentRun,
            status: event.data.status,
            statusMessage: event.data.message,
            executionState,
          });
        }
      }

      if (isParentRun) {
        const run = event.data.run;
        const currentTestId = run.testId ?? run.custom_test_id;
        trackAmplitudeEvent({
          eventType: `Parent run execution finished`,
          eventProperties: {
            partialExecution: run.isPartialExecution ?? false,
            runUntilExecution: run.isRunUntilExecution ?? false,
            cloudRun: !fromEditor,
            withDependencies: runs.length > 1,
            platform: run.platform,
            device: run.device,
            status: event.data.status,
            ...(currentTestId !== undefined && {
              testId: currentTestId,
            }),
          },
          groups: { organisation: companyName },
        });

        stopRunning();
      }
    },
    [testSuiteId, saveRunExecutionAsBaseline, companyData]
  );

  const setProcessingText = useCallback((text: string) => {
    const processingText = document.getElementById("stepProcessingDynamicText");
    if (processingText != null) {
      processingText.innerText = text;
    } else {
      const overlay = document.getElementById("overlay");
      let processingDiv = overlay.querySelector(".processing");

      if (processingDiv == null) {
        processingDiv = document.createElement("div");
        processingDiv.className =
          "processing absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center hidden";

        const processingText = document.createElement("p");
        processingText.innerText = text;
        processingText.className = "text-white text-lg";
        processingText.id = "stepProcessingDynamicText";

        processingDiv.appendChild(processingText);
        overlay.appendChild(processingDiv);
      }
      const scanLine = overlay.firstChild;

      overlay.style.display = "block";
      processingDiv.style.display = "block";
      scanLine.style.display = "none";
    }
  }, []);

  const startScanning = useCallback(() => {
    const overlay = document.getElementById("overlay");
    const scanLine = overlay.firstChild;
    let processingDiv = overlay.querySelector(".processing");

    // Reset elements' styles
    overlay.style.display = "block";
    scanLine.style.display = "block";
    if (processingDiv != null) {
      processingDiv.style.display = "none";
    }

    setTimeout(() => {
      scanLine.style.display = "none";
      if (processingDiv != null) {
        processingDiv.style.display = "block";
      }

      // Run your OCR process here
      // After the OCR process is done, hide the overlay
      // overlay.style.display = 'none';
    }, 5000);
  }, []);

  const startExecution = useCallback(
    ({
      runs,
      enableRetry,
    }: {
      runs: RunExecutionData[];
      enableRetry?: boolean;
    }) => {
      let auxExecutionState: ExecutionState = defaultExecutionState;

      const { mainObservable, stopObservable } = createObservables({
        runs,
        enableRetry: enableRetry ?? false,
      });
      setStopExecutionHandler((_) => {
        return () => {
          stopObservable();
          stopRunning();
        };
      });
      const subscription = mainObservable.subscribe({
        next: async (event) => {
          const fromEditor = testSuiteId == null;
          console.log("event", event);
          const date = new Date();
          const heartbeatElement = document.getElementById("heartbeat");
          if (heartbeatElement != null) {
            heartbeatElement.innerText = `${event.type},${date.getTime()}`;
          }

          if (event.type === "RUN_EXECUTION_STARTED") {
            const date = new Date();
            const startedTimeInEpoch = Math.ceil(date.getTime() / 1000);

            auxExecutionState = {
              ...auxExecutionState,
              runs: [
                ...auxExecutionState.runs,
                {
                  title: event.data.title,
                  executionSteps: [],
                  ...(event.data.hasPreRequestsToMake && {
                    preRequests: {
                      image: event.data.image,
                    },
                  }),
                  commands: event.data.commands,
                  promptSteps: event.data.promptSteps,
                  testId: event.data.testId,
                  startedTimeInEpoch: startedTimeInEpoch,
                },
              ],
            };

            setExecutionState(auxExecutionState);

            const runIndex = event.data.runIndex;
            if (!fromEditor) {
              const parentRunIndex = runs.length - 1;

              if (runIndex === 0) {
                startedRunningParentRunMutation.mutate({
                  run: runs[parentRunIndex],
                  startedTimeInEpoch,
                });
              }

              if (
                (runIndex === 0 && runs.length > 1) ||
                runIndex !== parentRunIndex
              ) {
                startedRunningDependencyMutation.mutate({
                  run: runs[runIndex],
                  parentRun: runs[parentRunIndex],
                  startedTimeInEpoch,
                });
              }
            }
            if (runIndex === 0) {
              const run = runs[runIndex];
              const currentTestId = run.testId;

              trackAmplitudeEvent({
                eventType: `Parent run execution started`,
                eventProperties: {
                  partialExecution: runs[runIndex].isPartialExecution ?? false,
                  runUntilExecution:
                    runs[runIndex].isRunUntilExecution ?? false,
                  cloudRun: !fromEditor,
                  withDependencies: runs.length > 1,
                  platform: runs[runIndex].platform,
                  device: runs[runIndex].device,
                  ...(currentTestId !== undefined && {
                    testId: currentTestId,
                  }),
                },
                groups: { organisation: companyData.name },
              });
            }
          } else if (event.type === "STEP_PROCESSING_TEXT_UPDATED") {
            if (event.data.text === "Analysing...") {
              startScanning();
            }
            setProcessingText(event.data.text);
          } else if (event.type === "NEW_STEP_INIT") {
            const auxRun =
              auxExecutionState.runs[auxExecutionState.runs.length - 1];
            const newRun: RunState = {
              ...auxRun,
              executionSteps: [
                ...auxRun.executionSteps,
                {
                  image: event.data.image,
                  gptCommands: [],
                  appetizeCommands: [],
                  actionHistory: [],
                  screenContent: [],
                },
              ],
            };

            const newRuns = replaceItemFomArray(
              auxExecutionState.runs,
              auxExecutionState.runs.length - 1,
              newRun
            );

            auxExecutionState = {
              ...auxExecutionState,
              runs: newRuns,
            };

            setExecutionState(auxExecutionState);
          } else if (event.type === "PRE_REQUESTS_RESPONSE") {
            const newRun = {
              ...auxExecutionState.runs[auxExecutionState.runs.length - 1],
              preRequests: {
                image: event.data.image,
                items: event.data.preRequestsResponseValues,
              },
            };

            const newRuns = replaceItemFomArray(
              auxExecutionState.runs,
              auxExecutionState.runs.length - 1,
              newRun
            );

            auxExecutionState = {
              ...auxExecutionState,
              runs: newRuns,
            };

            setExecutionState(auxExecutionState);
            setBuildLogsData((prev: BuildLogs) => ({
              ...prev,
              interactionLogs: [
                ...prev.interactionLogs,
                ...event.data.interactionLogs,
              ],
            }));
          } else if (
            event.type === "GPT_RESPONSE" ||
            event.type === "LAST_INTERACTION_LOG"
          ) {
            const isGptResponse = event.type === "GPT_RESPONSE";
            const isLastInteractionLogAndServerExecution =
              event.type === "LAST_INTERACTION_LOG" && !fromEditor;

            let newStep: ExecutionStepState = {
              actionHistory: isGptResponse ? event.data.actionHistory : [],
              screenContent: isGptResponse ? event.data.screenContent : [],
              appetizeCommands: isGptResponse
                ? event.data.appetizeCommandsToExecute
                : [],
              gptCommands:
                isGptResponse || isLastInteractionLogAndServerExecution
                  ? event.data.gptStep
                  : [],
              image: event.data.image,
              serverExecutionParams: event.data.serverExecutionParams,
            };

            const prevRun =
              auxExecutionState.runs[auxExecutionState.runs.length - 1];

            let newExecutionSteps = isGptResponse
              ? replaceItemFomArray(
                  prevRun.executionSteps,
                  prevRun.executionSteps.length - 1,
                  newStep
                )
              : !fromEditor
              ? [...(prevRun?.executionSteps ?? []), newStep]
              : prevRun?.executionSteps ?? [];

            const newRun = {
              ...prevRun,
              executionSteps: newExecutionSteps,
            };

            auxExecutionState = {
              ...auxExecutionState,
              runs: replaceItemFomArray(
                auxExecutionState.runs,
                auxExecutionState.runs.length - 1,
                newRun
              ),
            };
            setExecutionState(auxExecutionState);
            if (isGptResponse || isLastInteractionLogAndServerExecution) {
              setBuildLogsData((prev: BuildLogs) => {
                let newInteractionLogs = [
                  ...prev.interactionLogs,
                  event.data.interactionLog,
                ];
                if (event.data.shouldReplaceLastInteractionLog) {
                  newInteractionLogs = replaceItemFomArray(
                    prev.interactionLogs,
                    prev.interactionLogs.length - 1,
                    event.data.interactionLog
                  );
                }
                return {
                  ...prev,
                  interactionLogs: newInteractionLogs,
                };
              });
            }
          } else if (event.type === "UPDATE_APPETIZE_COMMAND") {
            // Update the last step with the new appetize command to allow annotating appetizeCommand taps
            const prevRun =
              auxExecutionState.runs[auxExecutionState.runs.length - 1];
            const newStep = {
              ...prevRun.executionSteps.at(-1),
              appetizeCommands: event.data.appetizeCommandsToExecute,
            };
            const newExecutionSteps = replaceItemFomArray(
              prevRun.executionSteps,
              prevRun.executionSteps.length - 1,
              newStep
            );
            const newRun = {
              ...prevRun,
              executionSteps: newExecutionSteps,
            };
            auxExecutionState = {
              ...auxExecutionState,
              runs: replaceItemFomArray(
                auxExecutionState.runs,
                auxExecutionState.runs.length - 1,
                newRun
              ),
            };
            setExecutionState(auxExecutionState);
            setBuildLogsData((prev: BuildLogs) => {
              const replacementCommand =
                event.data.appetizeCommandsToExecute[0];
              const targetSubstring = replacementCommand.split(";")[0].trim();
              const newGptCommands = prev?.interactionLogs
                ?.at(-1)
                ?.gptCommands?.map((command: string) =>
                  command.includes(targetSubstring)
                    ? command.replace(targetSubstring, replacementCommand)
                    : command
                );

              const newInteractionLogs = replaceItemFomArray(
                prev.interactionLogs,
                prev.interactionLogs.length - 1,
                {
                  ...prev.interactionLogs.at(-1),
                  gptCommands: newGptCommands,
                }
              );

              return {
                ...prev,
                interactionLogs: newInteractionLogs,
              };
            });
          } else if (event.type === "RUN_COMPLETED") {
            await handleConcludeExecution(
              event,
              runs,
              {
                ...auxExecutionState,
              },
              companyData.name
            );
          } else if (event.type === "RUN_RETRY") {
            auxExecutionState = { ...defaultExecutionState, isRunning: true };
            setExecutionState(auxExecutionState);
            setBuildLogsData(RESET);
          }
        },
        error: async (error) => {
          console.log("there was an error", error);
          if (error?.type === "RUN_COMPLETED") {
            await handleConcludeExecution(
              error,
              runs,
              {
                ...auxExecutionState,
              },
              companyData.name
            );
          } else {
            await handleConcludeExecution(
              {
                data: {
                  status: "blocked",
                  message: "Unexpected error",
                  run: runs.at(-1),
                },
              },
              runs,
              {
                ...auxExecutionState,
              },
              companyData.name
            );
            stopRunning();
          }
        },
        complete: () => {
          console.log("Observable completed");
        },
      });
      auxExecutionState = { ...auxExecutionState, isRunning: true };
      setExecutionState(auxExecutionState);
      return () => {
        subscription.unsubscribe();
      };
    },
    [
      createObservables,
      settingsData,
      handleConcludeExecution,
      companyData.name,
      user,
    ]
  );

  const stopRunning = useCallback(() => {
    setExecutionState((prev) => ({ ...prev, isRunning: false }));
    const overlay = document.getElementById("overlay");
    if (overlay != null) overlay.style.display = "none";
  }, []);

  const resetEditor = useCallback(() => {
    setStopExecutionHandler(null);
    setExecutionState(defaultExecutionState);
  }, []);

  useEffect(() => {
    const handleParseLogs = async () => {
      const fromEditor = testSuiteId == null;
      if (!fromEditor || executionState.runs.length === 0) {
        return [];
      }

      console.log("executionState", executionState);
      const parsedLogs: CachedScreenData[][] = await Promise.all(
        executionState.runs.map(async (run) => {
          let runParsedSteps: CachedScreenData[] = [];

          if (run.preRequests) {
            const { image, items } = run.preRequests;
            if (items) {
              runParsedSteps.unshift(
                ...items.map((item) => ({
                  screenshotUrl: image!,
                  appetizeCommand: "Executed network request",
                  gptCommand: item.curl,
                  preRequestResponse: JSON.stringify(item.data, null, 1),
                  statusCode: item.status,
                  stepNumber: "0",
                  prompt: "Executing network request",
                  fromCache: false,
                  title: "pre-requests",
                }))
              );
            } else {
              // Display loading spinner on screenshot
              runParsedSteps.unshift({
                screenshotUrl: image!,
                appetizeCommand: "Executed network request",
                gptCommand: "",
                stepNumber: "0",
                prompt: "Generating...",
                fromCache: false,
                title: "pre-requests",
              });
            }
          }

          const runSteps: CachedScreenData[] = await Promise.all(
            run.executionSteps.map(async (step, index) => {
              const annotatedScreenshot = await annotateScreenshot(
                step.image,
                step.appetizeCommands
              );

              const {
                reasoning,
                commands,
                step_number,
                step_description,
                fromCache,
                step_id,
              } = parseGptCommandsResponse(step.gptCommands ?? []);

              let stepNumberInt = parseInt(step_number);
              const promptText = getPromptText(
                stepNumberInt,
                run.commands,
                step_description,
                step_id,
                run?.promptSteps ?? []
              );

              console.log("Returned prompt text", { promptText });

              return {
                annotatedScreenshotUrl: annotatedScreenshot,
                screenshotUrl: step.image,
                appetizeCommand: step.appetizeCommands?.join("\n") ?? [],
                gptCommand: commands,
                reasoning: reasoning,
                stepNumber: step_number,
                prompt: promptText ?? "Generating...",
                fromCache: fromCache,
                title:
                  executionState.runs[executionState.runs.length - 1].title !==
                  run.title
                    ? run.title
                    : "parent",
                serverExecutionParams: step.serverExecutionParams,
              };
            })
          );

          runParsedSteps.push(...runSteps);
          return runParsedSteps;
        })
      );

      const flattenedParsedLogs = parsedLogs.flat();
      setParsedExecutionLogs(flattenedParsedLogs);
    };
    handleParseLogs();
  }, [executionState, testSuiteId]);

  useEffect(() => {
    return () => {
      stopExecutionHandler?.();
    };
  }, [stopExecutionHandler]);

  return {
    startExecution,
    stopExecution: stopExecutionHandler,
    startedRunningParentRunMutation,
    concludedParentRunMutation,
    resetEditor,
    executionState,
    parsedExecutionLogs,
  };
};

export default useExecuteSteps;

export type { ExecutionStepState };
