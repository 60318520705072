import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import {
  playbackTimestampAtomFromRunId,
  videoPlayerAtom,
} from "../atoms/playback-timestamp-atom";
import { useSetAtom } from "jotai";
import { useParams } from "react-router-dom";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  const { runId } = useParams();
  const playbackTimestampAtom = playbackTimestampAtomFromRunId(runId!);
  const setVideoPlayer = useSetAtom(videoPlayerAtom);

  const setPlaybackTimestamp = useSetAtom(playbackTimestampAtom);

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        player.on("timeupdate", (e) => {
          setPlaybackTimestamp(player.currentTime() * 1000);
        });
      }));

      setVideoPlayer(player);
      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      setVideoPlayer(player);

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }

    return () => {
      setVideoPlayer(null);
    };
  }, [onReady, options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player className={"h-full w-full"}>
      <div ref={videoRef} className={"h-full w-full"} />
    </div>
  );
};

export default VideoJS;
