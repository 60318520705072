import { atomWithReset, RESET } from "jotai/utils";
import {
  CachedScreenData,
  CompanyData,
  PromptStep,
  SessionLog,
} from "../../../utils/types";
import {
  Box,
  Breakpoint,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Button from "@mui/material/Button";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtom } from "jotai";
import {
  Abc,
  ArrowForward,
  ArrowOutward,
  CalendarToday,
  Casino,
  Close,
  CropTwoTone,
  DataObject,
  Delete,
  FileCopy,
  PlayArrow,
} from "@mui/icons-material";
import { lightTheme } from "../../../themes";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  annotateScreenshot,
  calculateMinutes,
  calculateTotalWeight,
  capitalizeFirstLetter,
  determineModelVersion,
  getExecutionData,
  getFormattedDate,
  getImageDimensions,
  imageUrlToBase64,
  parseCommands,
  parseGptCommandsResponse,
  replaceEnvVars,
  transformTemplates,
} from "../../../utils/helpers";
import { useMutation } from "@tanstack/react-query";
import { useAtomValue } from "jotai/index";
import LoadingButton from "@mui/lab/LoadingButton";
import { createCustomCommand } from "../tip-tap-editor/command-suggestions/commands";
import SuggestionsBuilder from "../tip-tap-editor/command-suggestions/suggestions-builder";
import { from, lastValueFrom, mergeMap, tap } from "rxjs";
import { mainTipTapEditorAtom } from "../tip-tap-editor/tipTapEditor";
import styled from "@emotion/styled";
import {
  TIPTAP_COMMANDS_SUGGESTIONS,
  tiptapDoubleCurlySuggestions,
  tiptapTemplatesSuggestions
} from "../../../constants/suggestions-constants";

const LogDetailDialog = ({
  removeCachedSteps,
  setActiveTab,
  acceptedDynamicValues,
  companyData,
  setAuxDisplayPasteStep,
  parentTestId,
  buildId,
  platform,
}: {
  setActiveTab?: Dispatch<SetStateAction<string>>;
  setAuxDisplayPasteStep?: Dispatch<SetStateAction<boolean>>;
  removeCachedSteps?: (deleteAll: boolean, stepNumber?: number) => void;
  acceptedDynamicValues: string[];
  companyData: CompanyData;
  parentTestId?: string;
  buildId?: string;
  platform: string;
}) => {
  const [logDetailDialogState, setLogDetailDialogState] = useAtom(
    logDetailDialogStateAtom
  );

  const handleClose = useCallback(
    (event: {}, reason: string) => {
      if (logDetailDialogState.redefiningStep) {
        if (setActiveTab == null) {
          setLogDetailDialogState((prev) => ({ ...prev, open: false }));
          setTimeout(() => {
            setLogDetailDialogState(RESET);
          }, 500);
        } else {
          setLogDetailDialogState((prev) => ({
            ...prev,
            redefiningStep: false,
          }));
        }
      } else {
        setLogDetailDialogState((prev) => ({ ...prev, open: false }));
        setTimeout(() => {
          setLogDetailDialogState(RESET);
        }, 500);
      }
    },
    [logDetailDialogState.redefiningStep]
  );

  const dialogProps = useMemo(() => {
    if (logDetailDialogState.redefiningStep) {
      return {
        maxWidth: "xl" as Breakpoint,
        sx: { ".MuiDialog-paper": { height: "100%" } },
      };
    } else {
      return { maxWidth: "xl" as Breakpoint };
    }
  }, [logDetailDialogState.redefiningStep]);

  const originalStepPrompt = useMemo(() => {
    if (logDetailDialogState.log?.stepNumber != null) {
      const parsedStepNumber = parseInt(logDetailDialogState.log?.stepNumber);
      if (!isNaN(parsedStepNumber)) {
        return logDetailDialogState.log?.serverExecutionParams?.promptSteps?.find(
          (step) => step.text.startsWith(`${parsedStepNumber}.`)
        );
      }
    }
    return undefined;
  }, [logDetailDialogState.log]);

  return (
    <Dialog
      {...dialogProps}
      open={logDetailDialogState.open}
      onClose={handleClose}
      fullWidth
    >
      {logDetailDialogState.redefiningStep ? (
        <RedefineStepDialogBody
          handleClose={handleClose}
          setActiveTab={setActiveTab}
          acceptedDynamicValues={acceptedDynamicValues}
          companyData={companyData}
          setAuxDisplayPasteStep={setAuxDisplayPasteStep}
          originalStepPrompt={originalStepPrompt}
          parentTestId={parentTestId}
          buildId={buildId!}
          platform={platform}
        />
      ) : (
        <DetailDialogBody
          removeCachedSteps={removeCachedSteps!}
          handleClose={handleClose}
          originalStepPrompt={originalStepPrompt}
        />
      )}
    </Dialog>
  );
};

const DetailDialogBody = ({
  removeCachedSteps,
  handleClose,
  originalStepPrompt,
}: {
  removeCachedSteps: (deleteAll: boolean, stepNumber?: number) => void;
  handleClose: Function;
  originalStepPrompt?: PromptStep;
}) => {
  const [logDetailDialogState, setLogDetailDialogState] = useAtom(
    logDetailDialogStateAtom
  );

  const log = logDetailDialogState.log;
  const isBaseline = logDetailDialogState.isBaseline;
  const isPreRequest = log?.title === "pre-requests";
  const stepIndex = logDetailDialogState.stepIndex;
  const shouldDisplayRedefine =
    !isBaseline &&
    !!logDetailDialogState.log?.serverExecutionParams &&
    originalStepPrompt?.id != null;
  // const shouldDisplayRedefine = false;
  return (
    <ContentWrapper shouldDisplayRedefine={shouldDisplayRedefine}>
      <div className={"absolute right-2 top-2 flex gap-1"}>
        {isBaseline && stepIndex != null && (
          <Tooltip
            title={`Remove step ${stepIndex + 1} from baseline`}
            placement="top"
          >
            <span>
              <IconButton
                onClick={() => {
                  removeCachedSteps(false, stepIndex);
                  setLogDetailDialogState((prev) => ({
                    ...prev,
                    open: false,
                  }));
                }}
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={"Close"}>
          <span>
            <IconButton onClick={() => handleClose({}, "closeIconButtonClose")}>
              <Close />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <InfoWrapper>
        <ScreenshotWrapper>
          <img
            style={{ maxHeight: "100%" }}
            src={log?.annotatedScreenshotUrl ?? log?.screenshotUrl}
          />
        </ScreenshotWrapper>
        <PromptDetailsWrapper>
          {log?.fromCache && (
            <div className={"flex flex-col gap-1"}>
              <p className={"text-lg font-bold"}>Action taken from cache</p>
            </div>
          )}
          <div className={"flex flex-col gap-1"}>
            <p className={"text-l font-bold"}>Prompt step executed</p>
            <p className={"text-s"}>{log?.prompt}</p>
          </div>
          <div className={"flex flex-col gap-1"}>
            <p className={"text-l font-bold"}>Action performed</p>
            <p className={"text-s"}>{log?.gptCommand}</p>
          </div>
          <div className={"flex flex-col gap-1"}>
            <p className={"text-l font-bold"}>
              {isPreRequest
                ? "Network request response"
                : "Reasoning from GPT Driver"}
            </p>
            {isPreRequest && log?.statusCode && (
              <p className={"text-l"}>{`Status code ${log.statusCode}`}</p>
            )}
            {isPreRequest ? (
              <SyntaxHighlighter
                language="json"
                style={stackoverflowDark}
                customStyle={{}}
              >
                {log!.preRequestResponse!}
              </SyntaxHighlighter>
            ) : (
              <CustomUl>
                {log?.reasoning
                  ?.replace("reasoning:", "")
                  ?.split(" - ")
                  ?.filter((item) => item)
                  ?.map((item, index) => (
                    <li key={index}>{capitalizeFirstLetter(item.trim())}</li>
                  ))}
              </CustomUl>
            )}
          </div>
        </PromptDetailsWrapper>
      </InfoWrapper>

      {shouldDisplayRedefine && (
        <>
          <Divider orientation="vertical" flexItem />
          <RedefineWrapper>
            <Chip label="New" color="primary" variant="outlined" />
            <Typography variant={"h6"}>Misinterpreted Step?</Typography>
            <Typography variant={"body1"}>
              Refine your step for better accuracy. Quickly run it multiple
              times to judge reliability.
            </Typography>
            <Button
              sx={{ marginTop: "8px" }}
              variant={"contained"}
              onClick={() => {
                setLogDetailDialogState((prev) => ({
                  ...prev,
                  redefiningStep: true,
                }));
              }}
              endIcon={<ArrowForward />}
            >
              Start
            </Button>
          </RedefineWrapper>
        </>
      )}
    </ContentWrapper>
  );
};

const CustomUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: disc;
  unicode-bidi: isolate;
  padding-inline-start: 16px;
`;

const RedefineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

const ContentWrapper = styled.div(
  ({ shouldDisplayRedefine }: { shouldDisplayRedefine: boolean }) => ({
    display: "grid",
    gridTemplateAreas: shouldDisplayRedefine
      ? '"info divider redefine"'
      : '"info"',
    gridTemplateColumns: shouldDisplayRedefine ? "60% auto" : undefined,
    columnGap: "48px",
    position: "relative",
    padding: "24px",
    overflow: "hidden",
  })
);

const PromptDetailsWrapper = styled.div`
  grid-area: prompt-details;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
`;

const InfoWrapper = styled.div`
  grid-area: info;
  display: grid;
  grid-template-areas: "screenshot prompt-details";
  grid-template-columns: 35% auto;
  column-gap: 32px;
  overflow: hidden;
  grid-template-rows: minmax(0, 1fr);
`;

const ScreenshotWrapper = styled.div`
  grid-area: screenshot;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RedefineStepDialogBody = ({
  handleClose,
  setActiveTab,
  acceptedDynamicValues,
  companyData,
  setAuxDisplayPasteStep,
  originalStepPrompt,
  parentTestId,
  buildId,
  platform,
}: {
  handleClose: Function;
  setActiveTab?: Dispatch<SetStateAction<string>>;
  setAuxDisplayPasteStep?: Dispatch<SetStateAction<boolean>>;
  acceptedDynamicValues: string[];
  originalStepPrompt?: PromptStep;
  companyData: CompanyData;
  parentTestId?: string;
  buildId: string;
  platform: string;
}) => {
  const [logDetailDialogState, setLogDetailDialogState] = useAtom(
    logDetailDialogStateAtom
  );
  const templatesRef = useRef(companyData.templates);
  const mainTipTapEditor = useAtomValue(mainTipTapEditorAtom);

  useEffect(() => {
    templatesRef.current = companyData.templates;
  }, [companyData.templates]);

  const isDependency = useMemo(
    () =>
      parentTestId != null &&
      logDetailDialogState.log?.serverExecutionParams?.testId !== parentTestId,
    [parentTestId]
  );

  const fromRecording: boolean = useMemo(
    () => setActiveTab == null,
    [setActiveTab]
  );

  const editor = useEditor({
    content: `<p>${
      originalStepPrompt?.plainText ?? logDetailDialogState.sessionLog?.prompt
    }</p>`,
    editorProps: {
      attributes: {
        class:
          "flex-1 max-w-none w-0 prose prose-invert focus:outline-none h-[360px] overflow-auto px-3 prose-p:m-0",
      },
    },
    extensions: [
      StarterKit,
      ...(companyData.settings.enableCommandSuggestions
        ? [
            createCustomCommand({ name: "customCommand" }).configure({
              suggestion: SuggestionsBuilder({
                // array with all lowercase and uppercase letters
                chars: [...Array(26)].flatMap((_, i) => [
                  String.fromCharCode(i + 97),
                  String.fromCharCode(i + 65),
                ]),
                breakRegexWords: ["mpl"],
                items: (props) => {
                  const query = props.query;
                  return TIPTAP_COMMANDS_SUGGESTIONS.sort((a, b) => {
                    let weightA = calculateTotalWeight(a.alternatives, query);
                    let weightB = calculateTotalWeight(b.alternatives, query);

                    // Sort in descending order of weights
                    return weightB - weightA;
                  });
                },
              }),
            }),
            createCustomCommand({ name: "envVariablesCommand" }).configure({
              suggestion: SuggestionsBuilder({
                chars: ["{{"],
                startOfLine: false,
                oneCharTrigger: false,
                items: (props) => {
                  const query = props.query.replace("{{", "");
                  return tiptapDoubleCurlySuggestions(
                    acceptedDynamicValues
                  ).sort((a, b) => {
                    let weightA = calculateTotalWeight(a.alternatives, query);
                    let weightB = calculateTotalWeight(b.alternatives, query);

                    // Sort in descending order of weights
                    return weightB - weightA;
                  });
                },
              }),
            }),
            createCustomCommand({ name: "templateCommand" }).configure({
              suggestion: SuggestionsBuilder({
                chars: ["tmpl"],
                startOfLine: false,
                oneCharTrigger: false,
                items: (props) => {
                  const query = props.query;
                  return tiptapTemplatesSuggestions(templatesRef.current).sort(
                    (a, b) => {
                      let weightA = calculateTotalWeight(a.alternatives, query);
                      let weightB = calculateTotalWeight(b.alternatives, query);

                      // Sort in descending order of weights
                      return weightB - weightA;
                    }
                  );
                },
              }),
            }),
          ]
        : []),
    ],
  });

  const redefineRunMutation = useMutation({
    mutationKey: ["redefineRunMutation"],
    mutationFn: async () => {
      setLogs((prev) => [prev.at(0)!]);
      const currentDate = getFormattedDate(new Date());

      const { parsedCommands } = parseCommands({
        commands: editor!.getText(),
        promptSteps: [],
        envVars: logDetailDialogState.log?.serverExecutionParams?.envVars ?? [],
        runInputValues:
          logDetailDialogState.log?.serverExecutionParams?.runInputValues ?? [],
        currentTemplateTimestamp: { current: null },
        actionHistory:
          logDetailDialogState.log?.serverExecutionParams?.actionHistory ??
          logDetailDialogState.sessionLog?.actionHistory ??
          [],
        shortenCommandStringEnabled:
          companyData.settings.shortenCommandStringEnabled ?? false,
        shortenCommandStringFromTopEnabled:
          companyData.settings.shortenCommandStringFromTopEnabled ?? false,
      });

      let imageUrl = logDetailDialogState.log?.screenshotUrl;
      let sessionLogScreenshotDimensions;

      if (fromRecording || logDetailDialogState.sessionLog != null) {
        const convertedImage = await imageUrlToBase64(
          logDetailDialogState.log?.screenshotUrl ??
            logDetailDialogState.sessionLog?.screenshotUrl ??
            ""
        );
        imageUrl = convertedImage as string;
        if (logDetailDialogState.sessionLog != null) {
          sessionLogScreenshotDimensions = await getImageDimensions(imageUrl);
        }
      }
      const shouldUseVisionModel = parsedCommands.includes("withVision:");

      const requestObject = {
        lambda_flow: "get_next_step",
        current_date: currentDate,
        base64_screenshot: imageUrl!.split(",")[1],
        getUI_elements:
          logDetailDialogState.log?.serverExecutionParams?.uiElements ?? [],
        test_task_string: parsedCommands,
        image_width:
          (logDetailDialogState.log?.serverExecutionParams?.screenshotDimensions
            ?.width ?? sessionLogScreenshotDimensions!.width) - 1, // subtract 1, since appetize allows pixels in range <0, image_dimension - 1>
        image_height:
          (logDetailDialogState.log?.serverExecutionParams?.screenshotDimensions
            ?.height ?? sessionLogScreenshotDimensions!.height) - 1,
        action_history:
          logDetailDialogState.log?.serverExecutionParams?.actionHistory ??
          logDetailDialogState.sessionLog?.actionHistory ??
          [],
        orgKey: companyData.organisationId,
        uploadId: buildId,
        platform,
        executionId: 123,
        step: logDetailDialogState.log?.serverExecutionParams?.step ?? 0,
        template_images: transformTemplates(companyData.templates ?? []),
        model_provider: "vellum",
        model_version: determineModelVersion(false, shouldUseVisionModel, true, companyData),
        fallbackModel: companyData.fallbackModel,
        utilize_fullTextAnnotation:
          companyData.settings.utilizeFullTextAnnotation,
        enableSortingOCR: companyData.settings.enableSortingOCR,
        enableActionHistoryCut: companyData.settings.enableActionHistoryCut,
        removeOverlappingText: companyData.settings.removeOverlappingText,
        currentAndPreviousScreenMatch:
          logDetailDialogState.log?.serverExecutionParams
            ?.currentAndPreviousScreenMatch ?? false,
        popupDetectionEnabled: companyData.settings.popupDetectionEnabled,
        ocrProvider: companyData.settings.ocrProvider,
        popupDetectionParameters: companyData.settings.popupDetectionParameters,
        templatesDetectionParameters:
          companyData.settings.templatesDetectionParameters,
        disabledButtonsDetectionEnabled:
          companyData.settings.disabledButtonsDetectionEnabled,
        disabledButtonsDetectionParameters:
          companyData.settings.disabledButtonsDetectionParameters,
      };

      const observable = from(Array.from({ length: times }, (v, k) => k)).pipe(
        mergeMap(async (request) => {
          const response = await getExecutionData(requestObject);

          const { reasoning, commands, step_number, step_description } =
            parseGptCommandsResponse(response.gptCommands ?? []);

          let appetizeCommands = response.appetizeCommands;
          const envVars =
            logDetailDialogState.log?.serverExecutionParams?.envVars ?? [];
          if (envVars.length > 0) {
            appetizeCommands = appetizeCommands.map((value) =>
              value.replace(
                /{{(.*?)}}/g,
                (match, key) =>
                  replaceEnvVars(key.trim(), envVars, true) || value
              )
            );
          }
          const annotatedScreenshot = await annotateScreenshot(
            logDetailDialogState.log?.screenshotUrl ??
              logDetailDialogState.sessionLog?.screenshotUrl,
            response.appetizeCommands ?? []
          );

          return {
            ...logDetailDialogState.log!,
            id: Date.now(),
            gptCommand: commands,
            reasoning: reasoning,
            stepNumber: step_number,
            fromCache: false,
            prompt: step_description,
            appetizeCommand: appetizeCommands,
            annotatedScreenshotUrl: annotatedScreenshot,
          };
        }, 3),
        tap((response) => {
          setLogs((prev) => [...prev, response]);
        })
      );
      return await lastValueFrom(observable);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    setSelectedLog(logs.at(-1)!);
  }, [redefineRunMutation.isSuccess]);

  const [times, setTimes] = useState(3);
  const [logs, setLogs] = useState([
    logDetailDialogState.log ?? logDetailDialogState.sessionLog,
  ]);
  const [selectedLog, setSelectedLog] = useState(
    logDetailDialogState.log ?? logDetailDialogState.sessionLog
  );

  const minutes = useMemo(() => calculateMinutes(times), [times]);
  const [textCopied, setTextCopied] = useState(false);

  useEffect(() => {
    if (textCopied) {
      setTimeout(() => {
        setTextCopied(false);
      }, 3000);
    }
  }, [textCopied]);

  const handleCopyEditorText = useCallback(async () => {
    await navigator.clipboard.writeText(editor!.getText());
    setTextCopied(true);
  }, [editor]);

  const openExternalTest = useCallback(() => {
    const promptStepId = originalStepPrompt.id;
    window.open(
      `/gpt-driver/build/${buildId}/test/${
        logDetailDialogState.log?.serverExecutionParams?.testId
      }${promptStepId != null ? `?selectedNode=${promptStepId}` : ""}`,
      "_blank"
    );
  }, [logDetailDialogState.log, originalStepPrompt, buildId]);

  const handleCopyAndRedefine = useCallback(async () => {
    setActiveTab!("prompt");
    await navigator.clipboard.writeText(editor!.getText());
    const promptStepId = originalStepPrompt.id;

    if (promptStepId != null) {
      const node = mainTipTapEditor!
        .$nodes("paragraph")
        ?.find((nodePos) => nodePos.attributes.id === promptStepId);
      if (node != null) {
        mainTipTapEditor!.commands.setTextSelection({
          from: node.from,
          to: node.to,
        });
      }
    }

    setLogDetailDialogState((prev) => ({ ...prev, open: false }));
    setTimeout(() => {
      setLogDetailDialogState(RESET);
      if (!fromRecording) {
        mainTipTapEditor!.commands.focus();
        setAuxDisplayPasteStep?.(true);
      }
    }, 500);
  }, [
    setActiveTab,
    setAuxDisplayPasteStep,
    editor,
    isDependency,
    originalStepPrompt,
    mainTipTapEditor,
    fromRecording,
  ]);

  const handlePlay = useCallback(async () => {
    redefineRunMutation.mutate();
  }, []);
  return (
    <>
      <DialogContent sx={{ position: "relative", padding: 0 }}>
        <div className={"absolute right-2 top-2"}>
          <Tooltip title={"Close"}>
            <span>
              <IconButton
                onClick={() => handleClose({}, "closeIconButtonClose")}
              >
                <Close />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        <div className={"flex h-full overflow-auto"}>
          <Box
            sx={{
              backgroundColor: lightTheme.palette.background.paper,
            }}
            className={`flex flex-col gap-4 flex-[2_1_0%] p-4`}
          >
            <div className={"flex gap-2 overflow-x-auto flex-shrink-0"}>
              {logs.map((log, index) => (
                <div
                  key={index}
                  className={"basis-48 flex-shrink-0 flex flex-col gap-3"}
                  onClick={() => {
                    setSelectedLog(log);
                  }}
                >
                  <Typography
                    variant={"body1"}
                    sx={{
                      color: lightTheme.palette.text.primary,
                    }}
                  >
                    {index === 0 ? "Reference" : `Run ${index}`}
                  </Typography>
                  <img
                    style={{
                      border:
                        selectedLog?.id === log.id
                          ? `solid ${lightTheme.palette.primary.light} 4px`
                          : "dashed transparent 4px",
                    }}
                    className={"w-full"}
                    src={log?.annotatedScreenshotUrl ?? log?.screenshotUrl}
                  />
                </div>
              ))}
              {(logs.length === 1 || redefineRunMutation.isPending) &&
                Array.from(
                  {
                    length: redefineRunMutation.isPending
                      ? times - logs.length + 1
                      : times,
                  },
                  (_, index) => (
                    <div
                      key={index}
                      className={"basis-48 flex-shrink-0 flex flex-col gap-3"}
                    >
                      <Typography
                        variant={"body1"}
                        sx={{
                          color: lightTheme.palette.text.primary,
                        }}
                      >
                        {`Run ${
                          redefineRunMutation.isPending
                            ? logs.length + index
                            : index + 1
                        }`}
                      </Typography>
                      <div
                        className={"relative flex-1"}
                        style={{
                          border: `dashed ${lightTheme.palette.primary.light} 4px`,
                        }}
                      >
                        {redefineRunMutation.isPending && (
                          <CircularProgress
                            style={{
                              position: "absolute",
                              left: 0,
                              right: 0,
                              top: 0,
                              bottom: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className={"flex gap-4 overflow-auto"}>
              <div className={"flex flex-1 flex-col gap-2"}>
                <Typography
                  sx={{
                    color: lightTheme.palette.text.primary,
                    fontWeight: 500,
                  }}
                >
                  Reasoning from GPT Driver
                </Typography>
                <CustomUl style={{ color: lightTheme.palette.text.primary }}>
                  {typeof selectedLog?.reasoning === "string"
                    ? selectedLog?.reasoning
                        ?.replace("reasoning:", "")
                        ?.split(" - ")
                        ?.filter((item) => item)
                        ?.map((item, index) => (
                          <li key={index}>
                            {capitalizeFirstLetter(item.trim())}
                          </li>
                        ))
                    : selectedLog?.reasoning?.map((item, index) => (
                        <li key={index}>
                          {capitalizeFirstLetter(item.trim())}
                        </li>
                      ))}
                </CustomUl>
              </div>
              <div className={"flex-1 flex flex-col gap-2"}>
                <Typography
                  sx={{
                    color: lightTheme.palette.text.primary,
                    fontWeight: 500,
                  }}
                >
                  Gpt Commands
                </Typography>
                <Typography sx={{ color: lightTheme.palette.text.primary }}>
                  {selectedLog?.gptCommand ??
                    selectedLog?.gptCommands?.join("\n")}
                </Typography>
              </div>
            </div>
          </Box>
          <Divider orientation="vertical" flexItem />
          <div className={"flex flex-col flex-1 p-5 gap-4"}>
            <Typography
              sx={{
                fontWeight: 500,
              }}
              variant={"h6"}
            >
              Run with the refined prompt
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
              }}
              variant={"body1"}
            >
              Original prompt
            </Typography>
            <Typography variant={"body2"}>
              {originalStepPrompt?.plainText ??
                logDetailDialogState.sessionLog?.prompt}
            </Typography>
            <Paper sx={{ flex: 1, paddingTop: "16px" }}>
              <EditorContent className={"flex"} editor={editor} />
            </Paper>
            {redefineRunMutation.isSuccess && (
              <div className={"flex flex-col gap-2 items-start"}>
                <Typography>Did the new prompt work?</Typography>
                {fromRecording ||
                isDependency ||
                logDetailDialogState.sessionLog != null ? (
                  <div className={"flex gap-3 items-center"}>
                    <Button
                      onClick={handleCopyEditorText}
                      endIcon={<FileCopy />}
                      variant={"contained"}
                      size={"small"}
                    >
                      {textCopied ? "Copied!" : "Yes, Copy"}
                    </Button>
                    {logDetailDialogState.sessionLog == null && (
                      <>
                        <p>&</p>
                        <Button
                          onClick={openExternalTest}
                          endIcon={<ArrowOutward />}
                          variant={"contained"}
                          size={"small"}
                        >
                          Open dependency
                        </Button>
                      </>
                    )}
                  </div>
                ) : (
                  <Button
                    onClick={handleCopyAndRedefine}
                    endIcon={<FileCopy />}
                    variant={"contained"}
                  >
                    Yes, copy and exit
                  </Button>
                )}
              </div>
            )}
            <div className={"flex justify-between"}>
              <div className={"flex flex-col gap-2 justify-center"}>
                <Typography variant={"body1"}>Estimated test time:</Typography>
                <Typography variant={"body2"}>
                  {minutes}-{minutes + 1} minutes
                </Typography>
              </div>
              <div className={"flex gap-4  items-center"}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Times
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={times}
                    disabled={redefineRunMutation.isPending}
                    onChange={(event) => {
                      setTimes(event.target.value!);
                    }}
                    label="Times"
                  >
                    <MenuItem value={1}>1 times</MenuItem>
                    <MenuItem value={2}>2 times</MenuItem>
                    <MenuItem value={3}>3 times</MenuItem>
                    <MenuItem value={5}>5 times</MenuItem>
                    <MenuItem value={10}>10 times</MenuItem>
                  </Select>
                </FormControl>
                <Tooltip
                  title={redefineRunMutation.isPending ? "Running" : "Run"}
                >
                  <span>
                    <LoadingButton
                      variant={"contained"}
                      size={"large"}
                      onClick={handlePlay}
                      loading={redefineRunMutation.isPending}
                    >
                      <PlayArrow />
                    </LoadingButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

const logDetailDialogStateAtom = atomWithReset<{
  open: boolean;
  isBaseline: boolean;
  redefiningStep: boolean;
  log?: CachedScreenData;
  sessionLog?: SessionLog;
  stepIndex?: number;
}>({
  open: false,
  redefiningStep: false,
  isBaseline: false,
});

export { LogDetailDialog, logDetailDialogStateAtom };
