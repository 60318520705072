import { useLoaderData, useLocation, useParams } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef,
} from "react";
import {
  BuildData,
  type LoaderApp,
  type Test,
  UnparsedTest,
  Dependency,
  SessionConfigData,
  CompanyData,
} from "../utils/types";
import styled from "@emotion/styled";

import Simulator from "../components/simulator";
import { testEditorPropertiesAtom } from "../atoms/test-editor-atom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import {
  customFirestore,
  customStorage,
  tipTapCollabJWTGenerator,
} from "../firebase";
import { atomWithReset, RESET } from "jotai/utils";
import {
  bufferToBase64,
  getTipTapExtensions,
  getTipTapSchema,
  trackSentryException,
} from "../utils/helpers";

import { appetizeClientAtom, buildLogsAtomWithId, sessionAtom } from "../atoms";
import { DeepLinkDialog } from "../components/deeplinkDialog";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { getDownloadURL, ref } from "firebase/storage";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CropScreenshotDialog, {
  CropScreenshotDialogStateAtom,
} from "../components/cropScreenshotDialog";
import { Autorenew, CameraAlt, Storage } from "@mui/icons-material";
import { Node } from "prosemirror-model";
import TestEditorGpt from "../components/test-editor-gpt/test_editor_gpt";
import { generateJSON } from "@tiptap/react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import { useAtom } from "jotai";
import { companyDataAtom } from "./test-run/test-run-atoms";
import { kAndroidOsName } from "../constants/appetize-constants";
import buildDataAtom from "../atoms/buildDataAtom";
import { DeviceProvider } from "./sessions/DeviceContext";
import DeviceWrapperAspectRatio from "./sessions/DeviceWrapperAspectRatio";
import InteractableDevice from "./sessions/InteractableDevice";
import { AuthContext } from "../auth/AuthContext";
import { customAlertStateAtom } from "../components/custom-alert";
import {
  bufferWhen,
  concatMap,
  debounceTime,
  filter,
  from,
  fromEvent,
  map,
  merge,
  mergeMap,
  tap,
} from "rxjs";
import { sessionMutationAtom } from "../utils/simulator";
import DialogActions from "@mui/material/DialogActions";

const TestBuilderPage = () => {
  const { buildId } = useParams();
  const { testId } = useParams();
  const setTestEditorProperties = useSetAtom(testEditorPropertiesAtom);
  const setSession = useSetAtom(sessionAtom);
  const queryClient = useQueryClient();
  const { user } = useContext<any>(AuthContext);
  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const [uiInspectorEnabled, setUiInspectorEnabled] = useState(false);

  useEffect(() => {
    return () => {
      setSession(null);
      setTestEditorProperties(RESET);
      buildLogsAtomWithId.remove(buildId);
      queryClient.removeQueries({ queryKey: ["deviceScreen"] });
    };
  }, [user]);

  const {
    data: buildData,
    isLoading: isLoadingBuild,
    isError: isErrorBuild,
    error: errorBuild,
  } = useAtomValue(
    buildDataAtom({ buildId: buildId, organizationId: user.companyId })
  );

  useEffect(() => {
    if (buildData != null && buildId != buildData?.id) {
      const description =
        buildId == null
          ? "Since you haven't selected a build, we picked the last uploaded build for you"
          : "Since the build you selected doesn't exist, we picked the last uploaded build for you";
      const title =
        buildId == null ? "No build selected" : "Build doesn't exist";
      setCustomAlertState({
        open: true,
        type: "info",
        title,
        description,
        maintainDisplayed: true,
      });
    }
  }, [buildId, buildData]);

  const {
    data: companyData,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
    error: errorCompany,
  } = useAtomValue(companyDataAtom(buildData?.organisationId));

  const {
    isLoading: isLoadingTest,
    data: testData,
    isError: isErrorTest,
    error: errorTest,
  } = useQuery<Test | null>({
    queryKey: ["getTestForTestEditor", testId!],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    gcTime: 0,
    enabled: companyData != null && buildData != null,
    queryFn: async ({ queryKey: [, id] }): Promise<Test | null> => {
      if (testId != null) {
        const docRef = doc(customFirestore, "custom-tests", testId!);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const unparsedTest: UnparsedTest = {
            ...(docSnap.data() as UnparsedTest),
            id: docSnap.id,
          };

          const parsedSettings = {
            launchParams: unparsedTest.settings?.launchParams ?? [],
            preRequests:
              unparsedTest.settings?.preRequests?.map((preRequest) => ({
                ...preRequest,
                delayBeforeRequest: preRequest.delayBeforeRequest ?? 0,
              })) ?? [],
            inputKeys: unparsedTest.settings?.inputKeys ?? [],
          };

          let parsedEnrichedInteractionsLogs = {
            promptGenerationScreenshots: {},
            enrichedInteractionLogs: {},
          } as {
            promptGenerationScreenshots: { [index: string]: string[] };
            enrichedInteractionLogs: {
              [index: string]: {
                enrichedLog: string;
                rawLog: string;
              }[];
            };
          };

          if (unparsedTest.enrichedInteractionsLogsPath != null) {
            const downloadUrl = await getDownloadURL(
              ref(customStorage, unparsedTest.enrichedInteractionsLogsPath)
            );

            const response = await axios.get(downloadUrl);
            const unparsedResponse = response.data as {
              [index: string]: {
                screenshot: string;
                screenshotUrl?: string;
                enrichedLog: string;
                rawLog: string;
              }[];
            };

            const startTime = Date.now();
            parsedEnrichedInteractionsLogs = await Object.keys(
              unparsedResponse
            ).reduce(async (prev, current) => {
              const parsedIndex = parseInt(current);
              const awaitedPrev = await prev;
              return {
                promptGenerationScreenshots: {
                  ...awaitedPrev.promptGenerationScreenshots,
                  [parsedIndex]: await Promise.all(
                    unparsedResponse[current].map(async (enrichedLog) => {
                      if (enrichedLog.screenshotUrl != null) {
                        return enrichedLog.screenshotUrl;
                      }

                      return await getDownloadURL(
                        ref(customStorage, enrichedLog.screenshot)
                      );
                    })
                  ),
                },
                enrichedInteractionLogs: {
                  ...awaitedPrev.enrichedInteractionLogs,
                  [parsedIndex]: unparsedResponse[current].map(
                    (enrichedLog) => ({
                      enrichedLog: enrichedLog.enrichedLog,
                      rawLog: enrichedLog.rawLog,
                    })
                  ),
                },
              };
            }, Promise.resolve({ promptGenerationScreenshots: {}, enrichedInteractionLogs: {} }));
            console.log(
              `Finished awaiting. Waiting took`,
              (Date.now() - startTime) / 1000
            );
          }

          const subCollectionRef = collection(docRef, "dependencies");
          const subCollectionSnapshot = await getDocs(subCollectionRef);
          const parsedDependencies = subCollectionSnapshot.docs.map<Dependency>(
            (doc) => {
              return { ...(doc.data() as Dependency), id: doc.id };
            }
          ) as Dependency[];

          let prosemirrorContent: Node;
          const testCommands = unparsedTest.commands;

          const tipTapSchema = getTipTapSchema();
          try {
            prosemirrorContent = Node.fromJSON(
              tipTapSchema,
              JSON.parse(testCommands)
            );
          } catch (e) {
            trackSentryException(e);
            prosemirrorContent = Node.fromJSON(
              tipTapSchema,
              generateJSON(
                `<p>${testCommands.replaceAll("\n", "<br/>")}</p>`,
                getTipTapExtensions()
              )
            );
          }
          setTestEditorProperties((prev) => ({
            ...prev,
            originalTestCommands: prosemirrorContent,
            testCommands: prosemirrorContent,
            originalRecordingTitle: unparsedTest.title,
            recordingTitle: unparsedTest.title,
          }));

          return {
            id: unparsedTest.id,
            title: unparsedTest.title,
            commands: unparsedTest.commands,
            organisationId: unparsedTest.organisationId,
            dependencyInputValues: unparsedTest.dependencyInputValues,
            enrichedInteractionsLogsPath:
              unparsedTest.enrichedInteractionsLogsPath,
            folderId: unparsedTest.folderId,
            cachedSteps: unparsedTest.cachedSteps,
            enrichedInteractionsLogs:
              parsedEnrichedInteractionsLogs.enrichedInteractionLogs,
            promptGenerationScreenshots:
              parsedEnrichedInteractionsLogs.promptGenerationScreenshots,
            settings: parsedSettings,
            dependencies: parsedDependencies,
          };
        } else {
          throw new Error("Test doesn't exist");
        }
      }
      return null;
    },
  });

  const tipTapJWTQuery = useQuery({
    queryKey: ["tip-tap-jwt"],
    queryFn: async () => {
      const response = await tipTapCollabJWTGenerator();
      return response.data;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    gcTime: 300000,
  });

  const isLoadingSimulator = useMemo(() => {
    return isLoadingBuild || isLoadingTest || isLoadingCompany;
  }, [isLoadingBuild, isLoadingTest, isLoadingCompany]);

  const isErrorSimulator = useMemo(() => {
    return isErrorTest || isErrorBuild || isErrorCompany;
  }, [isErrorTest, isErrorBuild, isErrorCompany]);

  const sessionConfig = useMemo<SessionConfigData | undefined>(() => {
    if (companyData == null || buildData == null) {
      return undefined;
    }

    const platform = buildData!.platform!.toLocaleLowerCase();
    return {
      platform,
      os:
        platform === kAndroidOsName.toLowerCase()
          ? companyData!.settings.defaultSessionConfig.osAndroid
          : companyData!.settings.defaultSessionConfig.osIos,
      device:
        platform === kAndroidOsName.toLowerCase()
          ? companyData!.settings.defaultSessionConfig.deviceAndroid
          : companyData!.settings.defaultSessionConfig.deviceIos,
      language: companyData!.settings.defaultSessionConfig.language,
      orientation: companyData!.settings.defaultSessionConfig.orientation,
      region: companyData!.settings.defaultSessionConfig.region,
    };
  }, [companyData, buildData]);

  return (
    <ContentWrapper>
      <SimulatorSection>
        <TestBuilderCustomAlert />
        {isLoadingSimulator && (
          <SpinnerWrapper>
            <CircularProgress />
          </SpinnerWrapper>
        )}

        {isErrorSimulator && (
          <p>
            Error:{" "}
            {errorBuild?.message ?? errorCompany?.message ?? errorTest?.message}
          </p>
        )}

        {/* if loading is finished, no error occured, and data is available display the test emulator */}
        {!isLoadingSimulator &&
          !isErrorSimulator &&
          buildData != null &&
          companyData != null && (
            <DeviceProvider
              settingsData={companyData.settings}
              uiInspectorEnabled={uiInspectorEnabled}
            >
              <SimulatorWrapper
                uiInspectorEnabled={uiInspectorEnabled}
                setUiInspectorEnabled={setUiInspectorEnabled}
                test={testData}
                buildData={buildData}
                companyData={companyData}
                sessionConfig={sessionConfig}
              />
            </DeviceProvider>
          )}
        <Overlay id="overlay">
          <ScanningLine />
        </Overlay>
      </SimulatorSection>

      <EditorSection>
        {!isLoadingSimulator &&
          !isErrorSimulator &&
          buildData != null &&
          companyData != null &&
          tipTapJWTQuery.data != null && (
            <TestEditorGpt
              test={testData}
              tipTapJWTData={tipTapJWTQuery.data!}
              buildData={buildData}
              companyData={companyData}
              sessionConfig={sessionConfig!}
            />
          )}
      </EditorSection>
    </ContentWrapper>
  );
};

const SimulatorWrapper = ({
  test,
  buildData,
  companyData,
  sessionConfig,
  uiInspectorEnabled,
  setUiInspectorEnabled,
}: {
  test: Test;
  buildData: BuildData;
  companyData: CompanyData;
  sessionConfig?: SessionConfigData;
  uiInspectorEnabled: boolean;
  setUiInspectorEnabled: Dispatch<SetStateAction<boolean>>;
}) => {
  const session = useAtomValue(sessionAtom);
  const [savingScreenshot, setSavingScreenshot] = useState(false);

  const loaderData = useLoaderData() as LoaderApp;
  const buildLogsData = useAtomValue(loaderData.buildLogsData);
  const videoFrames = buildLogsData.videoFrames;

  const setCropScreenshotDialogState = useSetAtom(
    CropScreenshotDialogStateAtom
  );
  const gptDriverSimulator = useAtomValue(sessionMutationAtom);
  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const saveScreenshot = useMutation({
    mutationKey: ["saveScreenshotForTemplate"],
    mutationFn: async () => {
      let base64Screenshot;
      if (companyData.settings.enableAppetizeClone) {
        base64Screenshot =
          "data:image/jpg;base64, " +
          (await gptDriverSimulator.data?.getScreenshot());
      } else {
        const lastVideoFrame = videoFrames
          .filter((value) => value?.relativeTimestamp < Date.now())
          .at(-1);
        base64Screenshot = `data:image/jpeg;base64,${bufferToBase64(
          lastVideoFrame?.buffer
        )}`;
      }

      setCropScreenshotDialogState({
        open: true,
        imageSrc: base64Screenshot,
        companyData: companyData,
        deviceName: sessionConfig?.device ?? "--",
      });

      return base64Screenshot;
    },
    onError: (error) => {
      console.error("Error:", error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred while taking screenshot",
        maintainDisplayed: false,
      });
    },
  });

  const rotateHandler = useCallback(() => {
    session.data?.rotate("left");
  }, [session]);

  const [openResetDialog, setOpenResetDialog] = useState(false);
  const handleOpenResetDialog = useCallback(() => setOpenResetDialog(true), []);

  const appetizeClient = useAtomValue(appetizeClientAtom);

  const startGptDriverSession = useCallback(() => {
    gptDriverSimulator.mutate({
      platform: sessionConfig!.platform,
      device: sessionConfig!.device,
      os: sessionConfig!.os,
      buildId: buildData.id,
      bundleId: buildData.bundle!,
      storagePath: buildData.storage_path,
      language:
        companyData.settings.defaultSessionConfig.language.split("_")[0],
      countryCode:
        companyData.settings.defaultSessionConfig.language.split("_")[1],
      proxy: companyData.settings.proxy,
      recordVideo: false,
      onCleanup: () => {
        gptDriverSimulator.reset();
      },
      organisationId: companyData.organisationId,
    });
  }, [buildData, companyData, sessionConfig, gptDriverSimulator]);

  useEffect(() => {
    if (gptDriverSimulator.error) {
      console.log("gptDriverSimulator.error", gptDriverSimulator.error);

      const errorMessage =
        gptDriverSimulator.error.message ?? "Unexpected error";
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: errorMessage,
        maintainDisplayed: false,
      });
    }
  }, [gptDriverSimulator.error]);

  const sessionResetHandler = useCallback(async () => {
    setOpenResetDialog(false);
    if (companyData.settings.enableAppetizeClone) {
      await gptDriverSimulator.data?.stop();
      startGptDriverSession();
    } else {
      await session.data?.end();
      await appetizeClient?.startSession();
    }
  }, [
    session,
    appetizeClient,
    gptDriverSimulator,
    companyData,
    startGptDriverSession,
  ]);

  const [activeSessionRunning, setActiveSessionRunning] = useState(false);
  const queryClient = useQueryClient();

  const appResetHandler = useCallback(() => {
    setOpenResetDialog(false);
    if (companyData.settings.enableAppetizeClone) {
      gptDriverSimulator.data?.launchApp();
    } else {
      session.data?.restartApp();
    }
  }, [session, companyData, gptDriverSimulator]);

  useEffect(() => {
    appetizeClient?.on("session", (session: any) => {
      setActiveSessionRunning(true);
    });
    session?.data?.on("end", (event: any) => {
      queryClient.invalidateQueries({ queryKey: ["deviceScreen"] });
      setActiveSessionRunning(false);
      setUiInspectorEnabled(false);
    });
    if (companyData?.settings?.enableAppetizeClone) {
      setActiveSessionRunning(gptDriverSimulator.data != null);
    }
  }, [session, appetizeClient, gptDriverSimulator, companyData]);

  useEffect(() => {
    let intervalId;
    if (
      companyData?.settings?.enableAppetizeClone &&
      gptDriverSimulator.data != null
    ) {
      intervalId = setInterval(() => {
        if (uiInspectorEnabled) {
          setUiInspectorEnabled(false);
          clearInterval(intervalId);
        }
      }, 60 * 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [companyData, uiInspectorEnabled, gptDriverSimulator]);

  return (
    <>
      {companyData != null && (
        <>
          <CropScreenshotDialog />
          <ResetDialog
            open={openResetDialog}
            setOpen={setOpenResetDialog}
            sessionResetHandler={sessionResetHandler}
            appResetHandler={
              companyData.settings.enableAppetizeClone ? null : appResetHandler
            }
          />
          <DeviceWrapperAspectRatio>
            <InteractableDevice
              enableGestureControl={uiInspectorEnabled}
              setEnableGestureControl={setUiInspectorEnabled}
              activeSessionRunning={activeSessionRunning}
            >
              {companyData.settings.enableAppetizeClone ? (
                <AppetizeClone
                  startGptDriverSession={startGptDriverSession}
                  companyData={companyData}
                  platform={sessionConfig?.platform}
                />
              ) : (
                <Simulator
                  company={companyData}
                  sessionConfig={sessionConfig!}
                  buildId={buildData.id}
                  appetizePublicKey={buildData.pubKey!}
                  params={
                    test?.settings?.launchParams?.reduce(
                      (prev, current) => ({
                        ...prev,
                        [current.key]: current.value,
                      }),
                      {}
                    ) ?? {}
                  }
                />
              )}
            </InteractableDevice>
          </DeviceWrapperAspectRatio>
          <ActionsWrapper>
            <Tooltip title={"Create a template"} placement="left">
              <span>
                <ActionButton
                  onClick={() => saveScreenshot.mutate()}
                  disabled={saveScreenshot.isPending || !activeSessionRunning}
                >
                  {saveScreenshot.isPending || savingScreenshot ? (
                    <CircularProgress size={"24px"} />
                  ) : (
                    <CameraAlt />
                  )}
                </ActionButton>
              </span>
            </Tooltip>
            <DeepLinkDialog
              activeSessionRunning={activeSessionRunning}
              session={session}
              companyData={companyData}
              gptDriverSimulator={gptDriverSimulator.data}
            />
            {!companyData.settings.enableAppetizeClone && (
              <Tooltip title={"Rotate device"} placement="left">
                <span>
                  <ActionButton
                    onClick={rotateHandler}
                    disabled={!activeSessionRunning}
                  >
                    <ScreenRotationIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title={"Show restart options"} placement="left">
              <span>
                <ActionButton
                  onClick={handleOpenResetDialog}
                  disabled={!activeSessionRunning}
                >
                  <Autorenew />
                </ActionButton>
              </span>
            </Tooltip>
            {companyData.settings.displayNetworkLogsOnEditor &&
              !companyData.settings.enableAppetizeClone && (
                <Tooltip title={"Display network logs"} placement="left">
                  <ActionButton
                    disabled={!activeSessionRunning}
                    onClick={() => {
                      window.open(session?.data?.networkInspectorUrl, "_blank");
                    }}
                  >
                    <Storage />
                  </ActionButton>
                </Tooltip>
              )}
            {companyData.settings.useAppetizeCommands && (
              <Tooltip title={"Display UI inspector"} placement="left">
                <span>
                  <ActionButton
                    onClick={() => {
                      setUiInspectorEnabled((prevState) => !prevState);
                    }}
                    disabled={!activeSessionRunning}
                  >
                    <LocationSearchingIcon
                      color={uiInspectorEnabled ? "success" : "inherit"}
                    />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
          </ActionsWrapper>
        </>
      )}
    </>
  );
};

const PRINTABLE_CHAR_REGEX = /^[\u0021-\u007E\u00A0-\u00FF]$/;

interface Trail {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

const AppetizeClone = ({
  startGptDriverSession,
  companyData,
  platform,
}: {
  startGptDriverSession: () => void;
  companyData: CompanyData;
  platform: string;
}) => {
  const {
    data: appetizeSessionData,
    isPending,
    status,
  } = useAtomValue(sessionMutationAtom);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = wrapperRef.current;
    if (!div || !appetizeSessionData) return;

    const paste$ = fromEvent<ClipboardEvent>(document, "paste").pipe(
      map((event) => ({
        type: "text" as const,
        content: event.clipboardData.getData("text"),
      }))
    );

    const keydown$ = fromEvent<KeyboardEvent>(document, "keydown").pipe(
      filter((event) => {
        // Only block ctrl/cmd combinations
        if (event.metaKey || event.ctrlKey) return false;

        return (
          event.key === "Backspace" ||
          event.key === " " ||
          PRINTABLE_CHAR_REGEX.test(event.key)
        );
      }),
      bufferWhen(() => {
        return merge(
          fromEvent<KeyboardEvent>(document, "keydown").pipe(debounceTime(300)),
          fromEvent<KeyboardEvent>(document, "keydown").pipe(
            filter((event) => event.key === " " || event.key === "Backspace")
          )
        );
      }),
      filter((events) => events.length > 0),
      mergeMap((events) => {
        const actions = [];
        let currentText = "";

        events.forEach((event) => {
          if (PRINTABLE_CHAR_REGEX.test(event.key)) {
            currentText += event.key;
          } else if (event.key === " " || event.key === "Backspace") {
            if (currentText) {
              actions.push({ type: "text" as const, content: currentText });
              currentText = "";
            }
            actions.push({
              type:
                event.key === " " ? ("space" as const) : ("delete" as const),
            });
          }
        });

        if (currentText) {
          actions.push({ type: "text" as const, content: currentText });
        }

        return from(actions);
      })
    );

    const events$ = merge(keydown$, paste$).pipe(
      concatMap((event) => {
        console.log("Executing:", event);
        return (() => {
          switch (event.type) {
            case "text":
              return from(
                appetizeSessionData.commands.type({ text: event.content })
              ).pipe(
                tap(() => console.log("Completed typing:", event.content))
              );
            case "space":
              return from(
                appetizeSessionData.commands.keyStroke({
                  keycode:
                    appetizeSessionData!.device.platform === "android"
                      ? 62
                      : 49,
                })
              ).pipe(tap(() => console.log("Completed space")));
            case "delete":
              return from(
                appetizeSessionData.commands.keyStroke({
                  keycode:
                    appetizeSessionData!.device.platform === "android"
                      ? 67
                      : 42,
                })
              ).pipe(tap(() => console.log("Completed delete")));
          }
        })();
      })
    );

    const subscription = events$.subscribe({
      next: () => console.log("Action completed"),
      error: (err) => console.error("Error:", err),
    });

    return () => subscription.unsubscribe();
  }, [appetizeSessionData]);

  const SWIPE_THRESHOLD = 50;
  const SWIPE_TIMEOUT = 1000;
  const TAP_MOVEMENT_THRESHOLD = 10;
  const TAP_TIMEOUT = 200;

  const touchRef = useRef({
    startTime: 0,
    isTouching: false,
    isSwipe: false,
    startElementCoords: { x: 0, y: 0 },
  });

  const cursorPosRef = useRef({ x: 0, y: 0 });
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const trailRef = useRef<Trail | null>(null);
  const [trail, setTrail] = useState<Trail | null>(null);
  const [isTapping, setIsTapping] = useState(false);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const rect = wrapperRef.current!.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const xPercent = x / rect.width;
      const yPercent = y / rect.height;

      const deviceX = Math.round(
        xPercent * appetizeSessionData!.device.screen.width
      );
      const deviceY = Math.round(
        yPercent * appetizeSessionData!.device.screen.height
      );

      appetizeSessionData!.commands.tap({
        coordinates: { x: deviceX, y: deviceY },
        duration: 200,
      });
    };

    const swipeStartListener = (event: MouseEvent | TouchEvent) => {
      const rect = wrapperRef.current!.getBoundingClientRect();
      const startEvent = event instanceof MouseEvent ? event : event.touches[0];

      const startX = startEvent.clientX - rect.left;
      const startY = startEvent.clientY - rect.top;

      touchRef.current = {
        startTime: Date.now(),
        isTouching: true,
        isSwipe: false,
        startElementCoords: { x: startX, y: startY },
      };

      cursorPosRef.current = {
        x: startX,
        y: startY,
      };
      setCursorPos(cursorPosRef.current);
      setIsTapping(true);
    };

    const swipeMoveListener = (event: MouseEvent | TouchEvent) => {
      if (!touchRef.current.isTouching) return;

      const rect = wrapperRef.current!.getBoundingClientRect();
      const moveEvent = event instanceof MouseEvent ? event : event.touches[0];

      const newPos = {
        x: moveEvent.clientX - rect.left,
        y: moveEvent.clientY - rect.top,
      };

      const deltaX = newPos.x - touchRef.current.startElementCoords.x;
      const deltaY = newPos.y - touchRef.current.startElementCoords.y;

      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      // If movement is greater than tap threshold, it's definitely not a tap
      if (distance > TAP_MOVEMENT_THRESHOLD) {
        // const timeDiff = Date.now() - touchRef.current.startTime;
        // if (distance >= SWIPE_THRESHOLD && timeDiff <= SWIPE_TIMEOUT) {
        if (distance >= SWIPE_THRESHOLD) {
          touchRef.current.isSwipe = true;
          setIsTapping(true);

          trailRef.current = {
            startX: touchRef.current.startElementCoords.x,
            startY: touchRef.current.startElementCoords.y,
            endX: newPos.x,
            endY: newPos.y,
          };
        }

        cursorPosRef.current = newPos;
        setTrail(trailRef.current);
        setCursorPos(cursorPosRef.current);
      }
    };

    const swipeEndListener = (event: MouseEvent | TouchEvent) => {
      const rect = wrapperRef.current!.getBoundingClientRect();
      const endEvent =
        event instanceof MouseEvent ? event : event.changedTouches[0];

      const endX = endEvent.clientX - rect.left;
      const endY = endEvent.clientY - rect.top;

      const duration = Date.now() - touchRef.current.startTime;

      const deltaX = endX - touchRef.current.startElementCoords.x;
      const deltaY = endY - touchRef.current.startElementCoords.y;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      if (touchRef.current.isSwipe) {
        const startXPercent =
          touchRef.current.startElementCoords.x / rect.width;
        const startYPercent =
          touchRef.current.startElementCoords.y / rect.height;
        const endXPercent = endX / rect.width;
        const endYPercent = endY / rect.height;

        const startDeviceX = Math.round(
          startXPercent * appetizeSessionData!.device.screen.width
        );
        const startDeviceY = Math.round(
          startYPercent * appetizeSessionData!.device.screen.height
        );
        const endDeviceX = Math.round(
          endXPercent * appetizeSessionData!.device.screen.width
        );
        const endDeviceY = Math.round(
          endYPercent * appetizeSessionData!.device.screen.height
        );

        appetizeSessionData?.commands.swipe({
          startCoordinates: { x: startDeviceX, y: startDeviceY },
          endCoordinates: { x: endDeviceX, y: endDeviceY },
          duration:
            companyData.settings.customSlideDurations[platform.toLowerCase()] ??
            duration,
        });
      } else if (
        distance <= TAP_MOVEMENT_THRESHOLD &&
        duration <= TAP_TIMEOUT
      ) {
        clickHandler(event as MouseEvent);
      }

      touchRef.current.isTouching = false;
      touchRef.current.isSwipe = false;
      trailRef.current = null;
      setTrail(trailRef.current);
      setIsTapping(false);
    };

    if (wrapperRef.current != null && appetizeSessionData) {
      wrapperRef.current.addEventListener("mousedown", swipeStartListener);
      wrapperRef.current.addEventListener("mousemove", swipeMoveListener);
      wrapperRef.current.addEventListener("mouseup", swipeEndListener);
      wrapperRef.current.addEventListener("mouseleave", swipeEndListener);
      wrapperRef.current.addEventListener("touchstart", swipeStartListener);
      wrapperRef.current.addEventListener("touchmove", swipeMoveListener);
      wrapperRef.current.addEventListener("touchend", swipeEndListener);
    }

    return () => {
      wrapperRef.current?.removeEventListener("mousedown", swipeStartListener);
      wrapperRef.current?.removeEventListener("mousemove", swipeMoveListener);
      wrapperRef.current?.removeEventListener("mouseup", swipeEndListener);
      wrapperRef.current?.removeEventListener("mouseleave", swipeEndListener);
      wrapperRef.current?.removeEventListener("touchstart", swipeStartListener);
      wrapperRef.current?.removeEventListener("touchmove", swipeMoveListener);
      wrapperRef.current?.removeEventListener("touchend", swipeEndListener);
    };
  }, [appetizeSessionData, companyData, platform]);

  const [hasVideo, setHasVideo] = useState(false);

  useEffect(() => {
    const checkForVideo = () => {
      if (wrapperRef.current) {
        // Find all video elements within the container
        const videos = wrapperRef.current.getElementsByTagName("video");
        setHasVideo(videos.length > 0);
      }
    };

    // Initial check
    checkForVideo();

    // Set up a MutationObserver to watch for changes in the container
    const observer = new MutationObserver(checkForVideo);

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current, {
        childList: true, // Watch for changes to child elements
        subtree: true, // Watch the entire subtree
      });
    }

    // Cleanup
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (appetizeSessionData == null) {
      setHasVideo(false);
    }

    return () => {
      setHasVideo(false);
    };
  }, [appetizeSessionData]);

  return (
    <AppetizeCloneDeviceWrapper
      tabIndex={0}
      ref={wrapperRef}
      id="deviceWrapper"
      activeSession={appetizeSessionData != null && hasVideo}
      onClick={
        appetizeSessionData == null && !isPending
          ? startGptDriverSession
          : undefined
      }
    >
      {(isPending || (appetizeSessionData && !hasVideo)) && (
        <CircularProgress />
      )}
      {!isPending && appetizeSessionData == null && (
        <TapToStartButton>Tap to start</TapToStartButton>
      )}
      {/* Tap cursor */}
      {isTapping && (
        <div
          className="absolute w-8 h-8 pointer-events-none"
          style={{
            left: cursorPos.x - 16,
            top: cursorPos.y - 16,
          }}
        >
          <svg viewBox="0 0 32 32" className="w-full h-full">
            <circle
              cx="16"
              cy="16"
              r="8"
              className="fill-blue-500 opacity-30"
            />
            <circle
              cx="16"
              cy="16"
              r="6"
              className="fill-blue-500 opacity-60"
            />
          </svg>
        </div>
      )}

      {/* Trail line */}
      <svg className="absolute top-0 left-0 w-full h-full pointer-events-none">
        <defs>
          <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="9"
            refY="3.5"
            orient="auto"
          >
            <polygon
              points="0 0, 10 3.5, 0 7"
              fill="#3B82F6"
              fillOpacity="0.3"
            />
          </marker>
        </defs>

        {trail && (
          <line
            x1={trail.startX}
            y1={trail.startY}
            x2={trail.endX}
            y2={trail.endY}
            stroke="#3B82F6"
            strokeWidth="2"
            className="opacity-30"
            markerEnd="url(#arrowhead)"
          />
        )}
      </svg>
    </AppetizeCloneDeviceWrapper>
  );
};

const TapToStartButton = styled.div`
  display: grid;
  place-content: center;
  padding: 16px 24px;
  border-radius: 24px;
  border: 2px solid rgba(76, 99, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 24px;
`;

const AppetizeClonePlaceholderWrapper = styled.div`
  grid-area: simulator-preview;
  border-radius: 8px;
  display: grid;
  place-content: center;
  border: 1px solid black;
  padding: 16px;
  height: 600px;
`;

const AppetizeCloneDeviceWrapper = styled.div(
  ({ activeSession }: { activeSession: boolean }) => ({
    position: "relative",
    display: activeSession ? "block" : "grid",
    ...(!activeSession && {
      placeContent: "center",
      backgroundColor: "rgba(76, 99, 255, 0.1)",
      cursor: "pointer",
    }),
    overflow: "hidden",
    height: "100%",
    width: "100%",
    borderRadius: activeSession ? "0px" : "40px",
    ...(!activeSession && {
      border: "2px solid #4C63FF",
    }),
  })
);

const ContentWrapper = styled.div`
  display: grid;
  grid-template-areas: "simulator editor";
  grid-template-columns: 45% 55%;
  background-color: #e8e8e8;
  height: 100%;
`;

const SimulatorSection = styled.div`
  grid-area: simulator;
  position: relative;
  padding-block: 32px;
  display: grid;
  //place-content: center;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionButton = styled.button`
  color: white;
  padding: 16px;
  border-radius: 9999px;
  background-color: #4c63ff;
  line-height: 0;

  &:disabled {
    color: #455a64;
    cursor: not-allowed;
    background-color: #bdbdbd;
  }

  &:hover {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }
`;

const EditorSection = styled.div`
  grid-area: editor;
  background-color: #313131;
  padding: 16px;
  overflow: auto;
`;

const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  display: none;
`;

const ScanningLine = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: #22c55e; /* green-500 */
  @keyframes scanning {
    0% {
      top: 0;
    }
    50% {
      top: 100%;
    }
    100% {
      top: 0;
    }
  }
  animation: scanning 5s linear forwards;
`;

const ResetDialog = ({
  open,
  setOpen,
  sessionResetHandler,
  appResetHandler,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sessionResetHandler: any;
  appResetHandler?: any;
}) => {
  const handleOpen = useCallback(() => setOpen((prev) => !prev), [setOpen]);
  const canRestartApp = appResetHandler != null;

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleOpen}>
      <DialogTitle>Restart option</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {canRestartApp
            ? "Do you want to restart the emulator or the app?"
            : "Do you want to restart the emulator?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={sessionResetHandler}>
          Restart Emulator
        </Button>
        {canRestartApp && (
          <Button variant={"outlined"} onClick={appResetHandler}>
            Restart App
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const TestBuilderCustomAlert = () => {
  const [state, setState] = useAtom(testBuilderCustomAlertStateAtom);
  const closeHandler = useCallback(() => {
    setState((prev) => ({ ...prev, open: false }));
  }, []);

  // Automatically close after 3 seconds if autoclose is true
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (state.open && state.autoclose) {
      timer = setTimeout(() => {
        closeHandler();
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [state.open, state.autoclose, closeHandler]);

  useEffect(() => {
    return () => {
      setState({ open: false });
    };
  }, []);

  return (
    <div
      className={`absolute z-20 pt-2 px-4 w-full ${
        state.open ? "visible" : "invisible"
      }`}
    >
      <Alert severity={state.type} onClose={closeHandler}>
        <AlertTitle>{state.title !== undefined && state.title}</AlertTitle>
        {state.description !== undefined && state.description}
        {state.listContent !== undefined && state.listContent.length > 0 && (
          <ul className="mt-2 ml-2 list-disc list-inside">
            {state.listContent.map((listItem, index) => (
              <li key={index}>{listItem}</li>
            ))}
          </ul>
        )}
      </Alert>
    </div>
  );
};

const testBuilderCustomAlertStateAtom = atomWithReset<{
  type?: "error" | "info" | "success" | "warning";
  title?: string;
  description?: string;
  listContent?: string[];
  open: boolean;
  autoclose: boolean;
}>({
  open: false,
  autoclose: false,
});

export {
  TestBuilderPage,
  TestBuilderCustomAlert,
  testBuilderCustomAlertStateAtom,
};
