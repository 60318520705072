import React, { useContext } from "react";
import { capitalizeFirstLetter } from "../utils/helpers";
import { AuthContext } from "../auth/AuthContext";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { IconButton, Tooltip } from "@mui/material";
import { Edit, FastForward, Http, Terminal } from "@mui/icons-material";
import { useAtomValue } from "jotai";
import { videoPlayerAtom } from "../atoms/playback-timestamp-atom";
import { PreRequestResponse } from "../utils/types";
import { stackoverflowDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";

const ReasoningAndCommands = ({
  title,
  absoluteTimestamp,
  relativeTimestamp,
  stepNumber,
  stepDescription,
  fromCache,
  commands,
  reasoning,
  promptActionHandler,
  handleMoveToNetworkLog,
  handleMoveToDeviceLog,
  preRequestResponse,
}: {
  title?: string;
  stepNumber?: string;
  stepDescription?: string;
  fromCache?: boolean;
  reasoning?: string[];
  commands: string;
  absoluteTimestamp: number;
  relativeTimestamp?: number;
  promptActionHandler?: () => void;
  handleMoveToNetworkLog?: (timestamp: number) => void;
  handleMoveToDeviceLog?: (timestamp: number) => void;
  preRequestResponse?: PreRequestResponse;
}) => {
  const useAuth = () => useContext<any>(AuthContext);
  const { user } = useAuth();

  const videoPlayer = useAtomValue(videoPlayerAtom);

  return (
    <ContentWrapper>
      <PropertyWrapper>
        <ActionHandlerWrapper>
          <p>{DateTime.fromMillis(absoluteTimestamp).toFormat("hh:mm:ss")}</p>
          {promptActionHandler && (
            <Tooltip title={"Refine step"}>
              <IconButton
                aria-label="Edit prompt"
                onClick={promptActionHandler}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {handleMoveToNetworkLog && relativeTimestamp && (
            <Tooltip title={"Jump to network log"}>
              <IconButton
                aria-label="Jump to network log"
                onClick={() => handleMoveToNetworkLog(relativeTimestamp)}
              >
                <Http />
              </IconButton>
            </Tooltip>
          )}
          {handleMoveToDeviceLog && relativeTimestamp && (
            <Tooltip title={"Jump to device log"}>
              <IconButton
                aria-label="Jump to device log"
                onClick={() => handleMoveToDeviceLog(relativeTimestamp)}
              >
                <Terminal />
              </IconButton>
            </Tooltip>
          )}
          {videoPlayer != null && (
            <Tooltip title={"Watch video from this point"}>
              <IconButton
                aria-label="Watch video from this point"
                onClick={() => {
                  if (videoPlayer != null) {
                    videoPlayer.currentTime(relativeTimestamp / 1000);
                  }
                }}
              >
                <FastForward />
              </IconButton>
            </Tooltip>
          )}
        </ActionHandlerWrapper>
      </PropertyWrapper>
      {title != null && (
        <PropertyWrapper>
          <Title>Test</Title>
          <p>{title}</p>
        </PropertyWrapper>
      )}
      {stepNumber && <Title>Step number {stepNumber}</Title>}
      {stepDescription && (
        <PropertyWrapper>
          <Title>Prompt</Title>
          <p>{stepDescription}</p>
        </PropertyWrapper>
      )}
      {user.role === "superUser" && fromCache && (
        <Title>Action taken from cache</Title>
      )}
      <PropertyWrapper>
        <Title>Commands</Title>
        <CustomUl>
          {commands
            .split("\n")
            .map((value, index) => value && <li key={index}>{value}</li>)}
        </CustomUl>
      </PropertyWrapper>
      {(reasoning ?? []).length > 0 && (
        <PropertyWrapper>
          <Title>Reasoning</Title>
          <CustomUl>
            {reasoning!.map((item, index) => (
              <li key={index}>{capitalizeFirstLetter(item.trim())}</li>
            ))}
          </CustomUl>
        </PropertyWrapper>
      )}
      {preRequestResponse != null && (
        <PropertyWrapper>
          <Title>Pre-request response</Title>
          {preRequestResponse?.status && (
            <CustomUl>
              <li
                className={"text-l"}
              >{`Status code ${preRequestResponse.status}`}</li>
            </CustomUl>
          )}
          <SyntaxHighlighter
            language="json"
            style={stackoverflowDark}
            customStyle={{}}
          >
            {JSON.stringify(preRequestResponse, null, 1)}
          </SyntaxHighlighter>
        </PropertyWrapper>
      )}
    </ContentWrapper>
  );
};

const CustomUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: disc;
  unicode-bidi: isolate;
  padding-inline-start: 16px;
`;

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 1rem;
  ul {
    font-size: 0.875rem;
  }
`;

const ActionHandlerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Title = styled.p`
  font-weight: 550;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default ReasoningAndCommands;
