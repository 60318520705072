import React, { useCallback, useState } from "react";
import { trackSentryException } from "../utils/helpers";
import styled from "@emotion/styled";
import { Link } from "@mui/icons-material";
import {
  TextField,
  Button,
  Dialog,
  Tooltip,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { CompanyData } from "../utils/types";
import { SimulatorSession } from "../utils/simulator";
import DialogActions from "@mui/material/DialogActions";
import { useSetAtom } from "jotai/index";
import { customAlertStateAtom } from "./custom-alert";

const DeepLinkDialog = ({
  session,
  activeSessionRunning,
  companyData,
  gptDriverSimulator,
}: {
  session: any;
  activeSessionRunning: boolean;
  companyData: CompanyData;
  gptDriverSimulator?: SimulatorSession;
}) => {
  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState<string>("");

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const openDeeplink = useCallback(async () => {
    toggleDialog();
    try {
      if (companyData.settings.enableAppetizeClone) {
        await gptDriverSimulator?.commands?.openDeepLinkUrl(link);
      } else {
        await session.data.openUrl(link);
      }
    } catch (e) {
      trackSentryException(e);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: `An error occurred while opening the deeplink: ${e}`,
      });
      console.error("An error occurred while opening the deeplink", e);
    }
  }, [session, companyData, gptDriverSimulator, link]);

  return (
    <>
      <Tooltip title={"Open Deeplink"} placement="left">
        <span>
          <ActionButton onClick={toggleDialog} disabled={!activeSessionRunning}>
            <Link />
          </ActionButton>
        </span>
      </Tooltip>
      <Dialog fullWidth maxWidth={"sm"} open={isOpen} onClose={toggleDialog}>
        <DialogTitle>Enter Deeplink / URL</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will open the deeplink in the simulator
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            label="Deeplink"
            fullWidth
            variant="standard"
            onChange={(e) => setLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} color={"error"} onClick={toggleDialog}>
            Cancel
          </Button>
          <Button variant={"contained"} onClick={openDeeplink}>
            Open URL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ActionButton = styled.button`
  color: white;
  padding: 16px;
  border-radius: 9999px;
  background-color: #4c63ff;

  &:disabled {
    color: #455a64;
    cursor: not-allowed;
    background-color: #bdbdbd;
  }

  &:hover {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }
`;

export { DeepLinkDialog };
