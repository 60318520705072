import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import { DeviceScreen, UIElement, UIElementBounds } from "./models";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { appetizeClientAtom, sessionAtom } from "../../atoms";
import { SettingsData } from "../../utils/types";
import { sessionMutationAtom } from "../../utils/simulator";

export interface ElementHint {
  text: string;
  isResourceId: boolean;
}

interface DeviceContextType {
  isLoading: boolean;
  hoveredElement: UIElement | null;
  setHoveredElement: (element: UIElement | null) => void;
  inspectedElement: UIElement | null;
  setInspectedElement: (id: UIElement | null) => void;
  deviceScreen: DeviceScreen | undefined;
  footerHint: ElementHint | null;
  setFooterHint: (footer: ElementHint | null) => void;
  currentCommandValue: string;
  setCurrentCommandValue: (id: string) => void;
}

type ElementFilter = (nodes: TreeNode[]) => TreeNode[];
type ElementLookupPredicate = (node: TreeNode) => boolean;

interface TreeNode {
  attributes?: { [key: string]: string };
  children?: TreeNode[];
  clickable?: boolean | null;
  enabled?: boolean | null;
  focused?: boolean | null;
  checked?: boolean | null;
  selected?: boolean | null;
  bounds?: UIElementBounds;
  path: string[];
}

interface UiElement {
  bounds: { x: number; y: number; width: number; height: number };
  treeNode: TreeNode;

  distanceTo(other: UiElement): number;
}

const Filters = {
  INDEX_COMPARATOR: (a: TreeNode, b: TreeNode) => {
    const aBounds = a?.bounds;
    const bBounds = b?.bounds;
    const yCompare =
      (aBounds?.y ?? Number.MAX_VALUE) - (bBounds?.y ?? Number.MAX_VALUE);
    if (yCompare !== 0) return yCompare;
    return (aBounds?.x ?? Number.MAX_VALUE) - (bBounds?.x ?? Number.MAX_VALUE);
  },

  intersect(filters: ElementFilter[]): ElementFilter {
    return (nodes: TreeNode[]) => {
      const intersected = filters
        .map((filter) => new Set(filter(nodes)))
        .reduce((a, b) => new Set([...a].filter((x) => b.has(x))));
      return Array.from(intersected) || nodes;
    };
  },

  compose(first: ElementFilter, second: ElementFilter): ElementFilter {
    return Filters.compose2([first, second]);
  },

  compose2(filters: ElementFilter[]): ElementFilter {
    return (nodes: TreeNode[]) =>
      filters.reduce((acc, filter) => filter(acc), nodes);
  },

  asFilter(predicate: ElementLookupPredicate): ElementFilter {
    return (nodes: TreeNode[]) => nodes.filter(predicate);
  },

  nonClickable(): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.clickable === false);
  },

  textMatches(regex: RegExp): ElementFilter {
    return (nodes: TreeNode[]) => {
      const filterText = (attr: string) =>
        nodes.filter((node) => {
          const value = node.attributes?.[attr];
          const strippedValue = value?.replace(/\n/g, " ");
          return value && (regex.test(value) || regex.test(strippedValue));
        });

      const textMatches = filterText("text");
      const hintTextMatches = filterText("hintText");
      const accessibilityTextMatches = filterText("accessibilityText");

      return Array.from(
        new Set([
          ...textMatches,
          ...hintTextMatches,
          ...accessibilityTextMatches,
        ])
      );
    };
  },

  idMatches(regex: RegExp): ElementFilter {
    return (nodes: TreeNode[]) => {
      const exactMatches = nodes.filter(
        (node) =>
          node.attributes?.["resource-id"] &&
          regex.test(node.attributes["resource-id"])
      );
      const idWithoutPrefixMatches = nodes.filter(
        (node) =>
          node.attributes?.["resource-id"] &&
          regex.test(node.attributes["resource-id"].split("/").pop() || "")
      );
      return Array.from(new Set([...exactMatches, ...idWithoutPrefixMatches]));
    };
  },

  sizeMatches(
    width: number | null = null,
    height: number | null = null,
    tolerance: number | null = null
  ): ElementLookupPredicate {
    return (node: TreeNode) => {
      const bounds = node?.bounds;
      const finalTolerance = tolerance ?? 0;
      if (!bounds) return false;

      if (width !== null && Math.abs(bounds.width - width) > finalTolerance)
        return false;
      if (height !== null && Math.abs(bounds.height - height) > finalTolerance)
        return false;

      return true;
    };
  },

  // below(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.y > other.bounds.y
  //   );
  // },
  //
  // above(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.y < other.bounds.y
  //   );
  // },
  //
  // leftOf(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.x < other.bounds.x
  //   );
  // },
  //
  // rightOf(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.x > other.bounds.x
  //   );
  // },

  // relativeTo(
  //   otherFilter: ElementFilter,
  //   predicate: (it: UiElement, other: UiElement) => boolean
  // ): ElementFilter {
  //   return (nodes: TreeNode[]) => {
  //     const matchingOthers = otherFilter(nodes)
  //       .map((node) => node.toUiElementOrNull())
  //       .filter(Boolean);
  //
  //     return nodes
  //       .map((node) => node.toUiElementOrNull())
  //       .filter(Boolean)
  //       .flatMap((element) =>
  //         matchingOthers
  //           .filter((other) => predicate(element!, other!))
  //           .map((other) => ({
  //             element,
  //             distance: element!.distanceTo(other!),
  //           }))
  //       )
  //       .sort((a, b) => a.distance - b.distance)
  //       .map(({ element }) => element.treeNode);
  //   };
  // },

  containsChild(other: UiElement): ElementLookupPredicate {
    return (node: TreeNode) => node.children?.includes(other.treeNode) ?? false;
  },

  containsDescendants(filters: ElementFilter[]): ElementFilter {
    return (nodes: TreeNode[]) => {
      return nodes.filter((node) =>
        filters.every((filter) =>
          node.children?.some((child) => filter([child]).length > 0)
        )
      );
    };
  },

  hasText(): ElementLookupPredicate {
    return (node: TreeNode) => !!node.attributes?.["text"];
  },

  // isSquare(): ElementLookupPredicate {
  //   return (node: TreeNode) => {
  //     const element = node.toUiElementOrNull();
  //     if (!element) return false;
  //     return (
  //       Math.abs(1.0 - element.bounds.width / element.bounds.height) < 0.03
  //     );
  //   };
  // },

  hasLongText(): ElementLookupPredicate {
    return (node: TreeNode) => (node.attributes?.["text"]?.length ?? 0) > 200;
  },

  index(idx: number): ElementFilter {
    return (nodes: TreeNode[]) =>
      [nodes.sort(Filters.INDEX_COMPARATOR)[idx]].filter(Boolean);
  },

  clickableFirst(): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.sort((a, b) => Number(b.clickable) - Number(a.clickable));
  },

  enabled(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.enabled === expected);
  },

  selected(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.selected === expected);
  },

  checked(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.checked === expected);
  },

  focused(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.focused === expected);
  },

  deepestMatchingElement(filter: ElementFilter): ElementFilter {
    return (nodes: TreeNode[]) =>
      filter(nodes).map((node) => {
        const matchingChildren = Filters.deepestMatchingElement(filter)(
          node.children ?? []
        );
        return matchingChildren[matchingChildren.length - 1] || node;
      });
  },
};

const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

interface DeviceProviderProps {
  children: ReactNode;
  defaultInspectedElement?: UIElement;
  settingsData: SettingsData;
  uiInspectorEnabled: boolean;
}

function createElementId(uiElement: UIElement, ids: Map<string, number>) {
  const parts = [
    uiElement.resourceId,
    uiElement.resourceIdIndex,
    uiElement.text,
    uiElement.textIndex,
  ].filter(Boolean);
  const fallbackId = uiElement.bounds
    ? `${uiElement.bounds.x},${uiElement.bounds.y},${uiElement.bounds.width},${uiElement.bounds.height}`
    : crypto.randomUUID();

  const id = parts.length === 0 ? fallbackId : parts.join("-");

  const index = ids.has(id) ? ids.get(id)! + 1 : 1;
  ids.set(id, index);

  return index === 1 ? id : `${id}-${index}`;
}

const transformIosUIElements = (iosElements: any[]): TreeNode[] => {
  // Helper to convert AXFrame string to bounds object
  const parseBounds = (element: any): UIElementBounds => {
    try {
      // Prioritize using the frame object
      if (element.frame && typeof element.frame === "object") {
        return {
          x: element.frame.x || 0,
          y: element.frame.y || 0,
          width: element.frame.width || 0,
          height: element.frame.height || 0,
        };
      }

      // Fall back to AXFrame if frame object is not available
      if (element.AXFrame && typeof element.AXFrame === "string") {
        const numbers = element.AXFrame.match(/-?\d+\.?\d*/g);
        if (numbers && numbers.length >= 4) {
          return {
            x: parseFloat(numbers[0]),
            y: parseFloat(numbers[1]),
            width: parseFloat(numbers[2]),
            height: parseFloat(numbers[3]),
          };
        }
      }
    } catch (error) {
      console.error("Error parsing bounds:", error);
    }

    // Default bounds if all parsing fails
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  };

  // Build parent-child relationships
  const buildHierarchy = (elements: any[]): TreeNode[] => {
    // Sort elements by Y position to maintain visual order
    const sortedElements = [...elements].sort((a, b) => {
      const boundsA = parseBounds(a);
      const boundsB = parseBounds(b);
      return boundsA.y - boundsB.y;
    });

    return sortedElements.map((element) => {
      const bounds = parseBounds(element);

      const node: TreeNode = {
        attributes: {
          text: element.AXLabel || "",
          "resource-id": element.AXUniqueId || "",
          "content-desc": element.role_description || "",
          hintText: element.help || "",
          accessibilityText: element.AXLabel || "",
        },
        clickable: element.type === "Button",
        enabled: element.enabled || false,
        focused: false, // iOS doesn't provide this directly
        checked: false, // iOS doesn't provide this directly
        selected: false, // iOS doesn't provide this directly
        bounds,
        path: [],
      };

      // Detect if this element might be a container for others
      const isContainer = ["Application", "Window", "Group"].includes(
        element.type
      );

      // Find potential children (elements that are visually contained within this element)
      if (isContainer) {
        const children = sortedElements.filter((child) => {
          if (child === element) return false;
          const childBounds = parseBounds(child.AXFrame, child.frame);
          return (
            childBounds.x >= bounds.x &&
            childBounds.y >= bounds.y &&
            childBounds.x + childBounds.width <= bounds.x + bounds.width &&
            childBounds.y + childBounds.height <= bounds.y + bounds.height
          );
        });

        if (children.length > 0) {
          node.children = buildHierarchy(children);
        }
      }

      return node;
    });
  };

  return buildHierarchy(iosElements);
};

const transformAndroidUIElements = (response: any): TreeNode[] => {
  const transformNode = (node: any): TreeNode => {
    const bounds = node["@bounds"] ? parseBounds(node["@bounds"]) : undefined;
    const transformed: TreeNode = {
      attributes: {
        text: node["@text"] || "",
        "resource-id": node["@resource-id"] || "",
        "content-desc": node["@content-desc"] || "",
        hintText: "", // Add if available in GPT response
        accessibilityText: node["@content-desc"] || "",
      },
      clickable: node["@clickable"] === "true",
      enabled: node["@enabled"] === "true",
      focused: node["@focused"] === "true",
      checked: node["@checked"] === "true",
      selected: node["@selected"] === "true",
      bounds,
      path: [], // Will be populated later
    };

    if (node.node) {
      transformed.children = Array.isArray(node.node)
        ? node.node.map(transformNode)
        : [transformNode(node.node)];
    }

    return transformed;
  };

  const parseBounds = (boundsStr: string): UIElementBounds => {
    const [x1, y1, x2, y2] = boundsStr.match(/\d+\.?\d*/g)?.map(Number) ?? [
      0, 0, 0, 0,
    ];
    return {
      x: x1,
      y: y1,
      width: x2 - x1,
      height: y2 - y1,
    };
  };

  return [transformNode(response.hierarchy.node)];
};

const gatherElements = (tree: TreeNode, list: TreeNode[]): TreeNode[] => {
  if (tree.children) {
    tree.children.forEach((child: any) => {
      gatherElements(child, list);
    });
  }
  list.push(tree);
  return list;
};

const processElements = (
  elements: TreeNode[],
  deviceData: any
): DeviceScreen => {
  const uiElements = elements.map<UIElement>((element) => {
    const bounds = element.bounds;
    const text = element?.attributes?.["text"];
    const path = element.path;
    const hintText = element?.attributes?.["hintText"];
    const accessibilityText = element?.attributes?.["accessibilityText"];
    const resourceId = element?.attributes?.["resource-id"];

    const id = [path, resourceId, accessibilityText, text, hintText]
      .filter(Boolean)
      .join("-");

    return {
      id,
      bounds,
      text,
      hintText,
      accessibilityText,
      resourceId,
    };
  });

  return {
    width: deviceData.device.screen.width,
    height: deviceData.device.screen.height,
    devicePixelRatio: deviceData.device.screen.devicePixelRatio,
    offset: deviceData.device.embed.screen.offset,
    elements: uiElements,
  };
};

export const DeviceProvider: React.FC<DeviceProviderProps> = ({
  children,
  defaultInspectedElement = null,
  settingsData,
  uiInspectorEnabled,
}) => {
  const session = useAtomValue(sessionAtom);
  const gptDriverSimulator = useAtomValue(sessionMutationAtom);
  const client = useAtomValue(appetizeClientAtom);
  const {
    data,
    isLoading,
    error: fetchError,
  } = useQuery<DeviceScreen>({
    queryKey: ["deviceScreen"],
    queryFn: async () => {
      try {
        if (session?.data != null && settingsData.useAppetizeCommands) {
          const unparsedUi = await session.data.getUI();
          console.log("unparsedUi", unparsedUi);
          const elements = gatherElements(unparsedUi.at(0), []).sort(
            Filters.INDEX_COMPARATOR
          );

          return processElements(elements, session.data);
        } else if (gptDriverSimulator?.data != null) {
          // Handle GPT driver simulator data
          const unparsedUI = await gptDriverSimulator.data.getUI();
          console.log("unparsedUI", unparsedUI);
          const transformedUi =
            gptDriverSimulator.data.device.platform.toLowerCase() === "ios"
              ? transformIosUIElements(unparsedUI)
              : transformAndroidUIElements(unparsedUI);
          console.log("transformedUi", transformedUi);
          const elements = gatherElements(transformedUi.at(0)!, []).sort(
            Filters.INDEX_COMPARATOR
          );
          console.log("elements", elements);
          return processElements(elements, {
            device: {
              screen: {
                width: gptDriverSimulator.data.device.screen.width,
                height: gptDriverSimulator.data.device.screen.height,
                devicePixelRatio: 1,
              },
              embed: {
                screen: {
                  offset: { x: 0, y: 0 },
                },
              },
            },
          });
        } else {
          return {
            width: client.device.screen.width,
            height: client.device.screen.height,
            elements: [],
          };
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    enabled:
      ((client != null && settingsData.useAppetizeCommands) ||
        gptDriverSimulator.data != null) &&
      uiInspectorEnabled,
    refetchInterval: 4000,
  });

  const [hoveredElement, setHoveredElement] = useState<UIElement | null>(null);
  const [inspectedElement, setInspectedElement] = useState<UIElement | null>(
    defaultInspectedElement
  );
  const [footerHint, setFooterHint] = useState<ElementHint | null>(null);
  const [currentCommandValue, setCurrentCommandValue] = useState<string>("");

  return (
    <DeviceContext.Provider
      value={{
        isLoading,
        hoveredElement,
        setHoveredElement,
        inspectedElement,
        setInspectedElement,
        footerHint,
        setFooterHint,
        deviceScreen: data,
        currentCommandValue,
        setCurrentCommandValue,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error("useDeviceContext must be used within a DeviceProvider");
  }
  return context;
};
