import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  ThemeProvider,
} from "@mui/material";
import {
  AccountTree,
  Stop,
  Delete,
  Undo,
  CameraAlt,
  ZoomIn,
} from "@mui/icons-material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CachedScreenData,
  CompanyData,
  SessionConfigData,
  Test,
} from "../../../utils/types";
import Button from "@mui/material/Button";
import { useQuery } from "@tanstack/react-query";
import {
  annotateScreenshot,
  imageUrlToBase64,
  trackSentryException,
} from "../../../utils/helpers";
import { LogDetailDialog, logDetailDialogStateAtom } from "./logDetailDialog";
import { useSetAtom } from "jotai";
import CropScreenshotDialog, {
  CropScreenshotDialogStateAtom,
} from "../../cropScreenshotDialog";
import styled from "@emotion/styled";
import { lightTheme } from "../../../themes";
import ZoomImageDialog, {
  zoomImageDialogStateAtom,
} from "../../zoomImageDialog";

const TestEditorExecutionLogs = ({
  baseline,
  currentLogs,
  removeCachedSteps,
  stopExecution,
  running,
  setActiveTab,
  acceptedDynamicValues,
  setAuxDisplayPasteStep,
  isPreparingData,
  companyData,
  sessionConfig,
  test,
  buildId,
}: {
  baseline: CachedScreenData[];
  currentLogs: CachedScreenData[];
  removeCachedSteps: (deleteAll: boolean, stepNumber?: number) => void;
  stopExecution: () => void;
  running: boolean;
  setActiveTab: Dispatch<SetStateAction<string>>;
  setAuxDisplayPasteStep: Dispatch<SetStateAction<boolean>>;
  acceptedDynamicValues: string[];
  isPreparingData: boolean;
  companyData: CompanyData;
  sessionConfig: SessionConfigData;
  test?: Test;
  buildId: string;
}) => {
  const annotatedBaselineData = useQuery({
    queryKey: ["annotatedBaselineData", baseline],
    queryFn: async () => {
      try {
        let annotatedData = await Promise.all(
          baseline
            .filter((cachedStep) => cachedStep.screenshotUrl != null)
            .map(async (cachedStep) => {
              let nonAnnotatedScreenshot = cachedStep.screenshotUrl;

              if (nonAnnotatedScreenshot.includes("http")) {
                try {
                  const image = await imageUrlToBase64(nonAnnotatedScreenshot);
                  nonAnnotatedScreenshot = image as string;
                } catch (e) {
                  console.error(e);
                  trackSentryException(
                    `Incorrect cached step URL: ${nonAnnotatedScreenshot}`
                  );
                  nonAnnotatedScreenshot = "";
                }
              }
              if (cachedStep.appetizeCommand) {
                const annotatedScreenshot = await annotateScreenshot(
                  nonAnnotatedScreenshot,
                  cachedStep.appetizeCommand.split("\n")
                );
                return { ...cachedStep, screenshotUrl: annotatedScreenshot };
              } else {
                return cachedStep;
              }
            })
        );

        annotatedData = annotatedData.filter(
          (cachedStep) => cachedStep.screenshotUrl !== ""
        );

        const defaultValue = {};
        return annotatedData.reduce((prev, current) => {
          return {
            ...prev,
            [current.title ?? "parent"]: [
              ...(prev[
                (current.title ?? "parent") as keyof typeof defaultValue
              ] ?? []),
              current,
            ],
          };
        }, defaultValue);
      } catch (e) {
        trackSentryException(e);
        console.error(e);
        return baseline;
      }
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const parsedCurrentLogs = useMemo(() => {
    const defaultValue = {};
    return currentLogs.reduce((prev, current) => {
      return {
        ...prev,
        [current.title ?? "parent"]: [
          ...(prev[(current.title ?? "parent") as keyof typeof defaultValue] ??
            []),
          current,
        ],
      };
    }, defaultValue);
  }, [currentLogs]);

  // console.log("parsedCurrentLogs", parsedCurrentLogs);

  useEffect(() => {
    console.log("baseline", baseline);
    console.log("currentLogs", currentLogs);
    console.log("annotatedBaselineData", annotatedBaselineData.data);
  }, [baseline, currentLogs, annotatedBaselineData.data]);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CropScreenshotDialog />
        <ZoomImageDialog />
      </ThemeProvider>
      <LogDetailDialog
        companyData={companyData}
        removeCachedSteps={removeCachedSteps}
        setActiveTab={setActiveTab}
        acceptedDynamicValues={acceptedDynamicValues}
        setAuxDisplayPasteStep={setAuxDisplayPasteStep}
        platform={sessionConfig.platform}
        parentTestId={test?.id}
        buildId={buildId}
      />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
          overflowY: "auto",
          padding: "16px",
          gap: "24px",
        }}
      >
        {annotatedBaselineData.isLoading && (
          <div className={"flex justify-center pb-3.5"}>
            <CircularProgress />
          </div>
        )}
        {annotatedBaselineData.data && (
          <>
            <TimelineSection
              title={"Baseline"}
              companyData={companyData}
              sessionConfig={sessionConfig}
              logs={annotatedBaselineData.data}
              clearBaselineButton={
                <Tooltip title={"Clear baseline"} placement="top">
                  <span>
                    <Button
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to clear the baseline?"
                          )
                        ) {
                          removeCachedSteps(true);
                        }
                      }}
                      variant={"outlined"}
                      color={"primary"}
                    >
                      <Delete />
                    </Button>
                  </span>
                </Tooltip>
              }
            />
            <TimelineSection
              stopExecution={stopExecution}
              companyData={companyData}
              sessionConfig={sessionConfig}
              running={running}
              title={"Current execution"}
              logs={parsedCurrentLogs}
              isPreparingData={isPreparingData}
            />
          </>
        )}
      </Paper>
    </>
  );
};

const TimelineSection = ({
  logs,
  title,
  stopExecution,
  running,
  clearBaselineButton,
  isPreparingData = false,
  companyData,
  sessionConfig,
}: {
  logs: { [title: string]: CachedScreenData[] };
  title: string;
  stopExecution?: () => void;
  running?: boolean;
  clearBaselineButton?: any;
  isPreparingData?: boolean;
  companyData: CompanyData;
  sessionConfig: SessionConfigData;
}) => {
  const logsTitles = useMemo(() => Object.keys(logs), [logs]);
  const isBaseline = clearBaselineButton !== undefined;

  return (
    <div className={"flex flex-col"}>
      <div className={"flex gap-3 items-center"}>
        <p>{title}</p>
        {running && stopExecution && (
          <Button variant="contained" onClick={stopExecution}>
            <Stop />
          </Button>
        )}
        {clearBaselineButton && logsTitles.length > 0 && clearBaselineButton}
      </div>
      {isPreparingData && (
        <div className={"flex gap-3 mt-2 items-center"}>
          <CircularProgress />
          <p>Preparing data</p>
        </div>
      )}

      {logsTitles.length > 0 && (
        <div className={"flex items-center mt-1"}>
          {logsTitles.map((logTitle, index) => (
            <div className={"flex items-center"} key={index}>
              {index !== 0 && (
                <div className={"bg-[#7C7C7C] h-[1px] w-10 mt-[1px]"} />
              )}
              <RunSteps
                logs={logs[logTitle]}
                dependencyTestTitle={logTitle}
                isFirstRun={index === 0}
                running={running ?? false}
                isBaseline={isBaseline}
                companyData={companyData}
                sessionConfig={sessionConfig}
              />
            </div>
          ))}

          <div className={"flex items-center h-[34px] pr-5 mt-[1px]"}>
            <div className={"bg-[#7C7C7C] h-[1px] w-[28px]"} />
            <div className={"flex flex-col py-3"}>
              <div className={"h-[16px]"}></div>
              {(!running || isBaseline) && (
                <p
                  className={
                    "border border-[#7C7C7C] text-[#7C7C7C] rounded-[20px] px-4 py-1 font-medium text-[10px] tall:text-[14px] w-max mt-5"
                  }
                >
                  Test end
                </p>
              )}
              <div className={"h-[37px]"}></div>
            </div>
          </div>
        </div>
      )}
      {logsTitles.length === 0 && isBaseline && (
        <p className={"text-base"}>
          Test has not been successfully run before.
        </p>
      )}
    </div>
  );
};

const RunSteps = ({
  logs,
  dependencyTestTitle,
  isFirstRun,
  running,
  isBaseline,
  companyData,
  sessionConfig,
}: {
  logs: CachedScreenData[];
  dependencyTestTitle?: string;
  isFirstRun: boolean;
  running: boolean;
  isBaseline: boolean;
  companyData: CompanyData;
  sessionConfig: SessionConfigData;
}) => {
  const setLogDetailDialogState = useSetAtom(logDetailDialogStateAtom);

  return (
    <div
      className={
        dependencyTestTitle != "parent"
          ? "flex flex-col border border-[#7C7C7C] rounded-[8px] px-3 pb-3 gap-1 relative"
          : "flex flex-col px-3 pb-3 gap-1 relative"
      }
    >
      <div
        className={`absolute bg-[#7C7C7C] h-[1px] top-1/2 bottom-1/2 ${
          !isFirstRun ? "left-0" : "left-[12px]"
        } right-0`}
      />
      <div className={"flex gap-2 text-[#7C7C7C] items-center"}>
        <div className={dependencyTestTitle == "parent" ? "invisible" : ""}>
          <AccountTree sx={{ fontSize: "12px" }} />
        </div>
        {dependencyTestTitle != "parent" && (
          <p className={"text-[11px] font-medium"}>
            {dependencyTestTitle!.toUpperCase()}
          </p>
        )}
      </div>
      <div className={"flex gap-10 relative"}>
        {logs.map((log, index) => (
          <LogWrapper
            className={"flex flex-col gap-2 items-center"}
            key={index}
            onClick={() => {
              setLogDetailDialogState({
                log,
                open: log.prompt !== "Generating...",
                stepIndex: index,
                isBaseline,
                redefiningStep: false,
              });
            }}
          >
            <StepScreenshot
              companyData={companyData}
              sessionConfig={sessionConfig}
              screenshotUrl={log.annotatedScreenshotUrl ?? log.screenshotUrl}
              promptStepText={log.prompt ?? ""}
              running={running}
            />
            <StepAction
              action={log.appetizeCommand}
              actionNumber={log.stepNumber}
            />
          </LogWrapper>
        ))}
      </div>
    </div>
  );
};

const LogWrapper = styled.div`
  &:hover button {
    opacity: 1;
  }

  & button {
    opacity: 0;
  }
`;

const StepScreenshot = ({
  screenshotUrl,
  promptStepText,
  running,
  companyData,
  sessionConfig,
}: {
  screenshotUrl: string;
  promptStepText: string;
  running: boolean;
  companyData: CompanyData;
  sessionConfig: SessionConfigData;
}) => {
  const isGenerating = promptStepText === "Generating...";
  const setCropScreenshotDialogState = useSetAtom(
    CropScreenshotDialogStateAtom
  );
  const setZoomImageDialogState = useSetAtom(zoomImageDialogStateAtom);

  return (
    <Tooltip
      title={!isGenerating || running ? promptStepText : null}
      placement={"top"}
    >
      <div>
        {isGenerating && (
          <div className={"absolute z-10 p-2"}>
            {running ? (
              <CircularProgress />
            ) : (
              <p className={"text-gray-400"}>Execution stopped</p>
            )}
          </div>
        )}
        <StepScreenshotImageWrapper isGenerating={isGenerating}>
          <img
            className={`max-w-[80px] tall:max-w-[118px] ${
              !isGenerating ? "cursor-pointer" : ""
            }`}
            src={screenshotUrl}
          />
          <ActionsRow>
            <Tooltip title={"Create template"} placement={"bottom"}>
              <span>
                <CustomActionButton
                  aria-label="create template"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCropScreenshotDialogState({
                      open: true,
                      imageSrc: screenshotUrl,
                      companyData: companyData,
                      deviceName: sessionConfig.device,
                    });
                  }}
                >
                  <CameraAlt />
                </CustomActionButton>
              </span>
            </Tooltip>
            <Tooltip title={"Enlarge image"} placement={"bottom"}>
              <span>
                <CustomActionButton
                  aria-label="Enlarge image"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setZoomImageDialogState({
                      open: true,
                      imageSrc: screenshotUrl,
                    });
                  }}
                >
                  <ZoomIn />
                </CustomActionButton>
              </span>
            </Tooltip>
          </ActionsRow>
        </StepScreenshotImageWrapper>
      </div>
    </Tooltip>
  );
};

const CustomActionButton = styled(IconButton)(() => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  transition: "opacity 300ms",
}));

//transform to object
const ActionsRow = styled.div`
  visibility: visible;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  top: 4px;
  right: 2px;
  width: 100%;
`;

const StepScreenshotImageWrapper = styled.div(
  ({ isGenerating }: { isGenerating: boolean }) => ({
    position: "relative",
    ...(isGenerating && {
      filter: "brightness(15%)",
    }),
  })
);

const StepAction = ({
  actionNumber,
  action,
}: {
  actionNumber?: string;
  action: string;
}) => {
  return (
    <Tooltip title={action}>
      <div className={"flex items-center gap-2 p-1 bg-[#C4C4C4] rounded"}>
        <p
          className={
            "text-[14px] font-normal text-[#7C7C7C] bg-[#D9D9D9] px-1 rounded-[2px]"
          }
        >
          {actionNumber === "" ? "-" : actionNumber}
        </p>
      </div>
    </Tooltip>
  );
};

export default TestEditorExecutionLogs;
